<template>
	<label :for="htmlFieldName">
		{{ field.name }}
		<span class="label-small" v-if="selectedOptionText">({{ selectedOptionText }})</span>
		<span class="label-small" v-else>{{ field.description }}</span>
	</label>
</template>

<script setup>
import { computed, inject, ref, toRef } from 'vue';
import { useField } from 'vee-validate';
import { escapeRegex } from '@/helpers';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	removePrefixFieldName: {
		type: String,
		required: false,
		default: '',
	},
});

const modelLookup = inject('modelLookup');
const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const { value } = useField(toRef(props, 'name'));
const model = computed(() => modelLookup(props.name));
const field = computed(() => model.value ? model.value.field : null);
const { value: prefixValue } = props.removePrefixFieldName ? useField(toRef(props, 'removePrefixFieldName')) : { value: ref(null) };
const prefixModel = computed(() => props.removePrefixFieldName ? modelLookup(props.removePrefixFieldName) : null);
const prefixField = computed(() => prefixModel.value ? prefixModel.value.field : null);

const selectedOptionText = computed(() => {
	const selectedOption = field.value && value.value ? field.value.options.find((x) => x.id === value.value) : null;
	const prefixOption = prefixField.value && prefixValue.value ? prefixField.value.options.find((x) => x.id === prefixValue.value) : null;
	return selectedOption ? (prefixOption ? selectedOption.value.replace(new RegExp(escapeRegex(prefixOption.value), 'i'), '').trim() : selectedOption.value) : null;
});
</script>
