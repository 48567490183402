import { getValueFromDto as getValue } from './_helper';

export default class User {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.externalId = getValue(dto, 'externalId', 'string', null);
		this.folderId = getValue(dto, 'folderId', 'string', null);
		this.email = getValue(dto, 'email', 'string', '');
		this.phoneNumber = getValue(dto, 'phoneNumber', 'string', '');
		this.emailConfirmed = getValue(dto, 'emailConfirmed', 'boolean', false);
		this.firstName = getValue(dto, 'firstName', 'string', '');
		this.lastName = getValue(dto, 'lastName', 'string', '');
		this.active = getValue(dto, 'active', 'boolean', false);
		this.isSuperAdmin = getValue(dto, 'isSuperAdmin', 'boolean', false);
		this.role = getValue(dto, 'role', 'string', '');
		this.companyId = getValue(dto, 'companyId', 'number', null);
	}

	get name() {
		return (this.firstName + ' ' + this.lastName).trim();
	}

	get initials() {
		const fi = this.firstName ? this.firstName.substr(0, 1) : '?';
		const li = this.lastName ? this.lastName.substr(0, 1) : '?';
		return (fi + li).toUpperCase();
	}

	get firstNameLastInitial() {
		const li = this.lastName ? this.lastName.substr(0, 1) : '?';
		return this.firstName + ' ' + li.toUpperCase() + '.';
	}
}
