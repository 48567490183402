import $store from '@/store/index';
import { onUnmounted, ref, watch } from 'vue';

export function onBeforeUnload(getter) {
	if ($store.state.environment === 'development') { return; }
	const listenerAdded = ref(false);
	function beforeUnloadListener(event) {
		event.preventDefault();
		return (event.returnValue = 'Are you sure you want to refresh? Unsaved changes may be lost.');
	}
	watch(getter, (unsaved) => {
		if (unsaved && !listenerAdded.value) {
			listenerAdded.value = true;
			addEventListener('beforeunload', beforeUnloadListener, { capture: true });
		} else if (!unsaved && listenerAdded.value) {
			listenerAdded.value = false;
			removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
		}
	});
	onUnmounted(() => {
		if (listenerAdded.value) {
			removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
		}
	});
}
