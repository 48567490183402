import { url as urlHelper } from '@/helpers';
import Credential from '@/models/Credential';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Get all credentials
	 * @returns (async) Returns an array of credentials if the request was successful, otherwise a Response.
	 */
	async getAll(decrypt = false) {
		const query = {
			decrypt
		};
		const url = urlHelper('/api/credential', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return (await response.json()).map(x => new Credential(x));
		} else {
			return response;
		}
	},
	/**
	 * Get a credential
	 * @param {id} Number Credential ID
	 * @returns (async) Returns a Credential if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/credential/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Credential(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Create a credential
	 * @param {model} Credential credential to create.
	 * @returns (async) Returns the new Credential if the request was successful, otherwise a Response.
	 */
	async create(model) {
		const url = '/api/credential';
		let response;
		try {
			response = await fetchWrap(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Credential(model);
		} else {
			return response;
		}
	},
	/**
	 * Update a credential
	 * @param {model} Credential credential to update.
	 * @returns (async) Returns the updated Credential if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/credential', {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Credential(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a credential
	 * @param {id} Number Credential ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the credential could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/credential/' + id, { method: 'DELETE' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
