<template>
	<transition
		name="toggle-height"
		@before-enter="beforeEnter"
		@enter="enter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
		@leave="leave">
		<slot />
	</transition>
</template>

<script setup>
import { nextTick, ref } from 'vue';

const duration = ref('');
const timing = ref('');

function beforeEnter(el) {
	el.style.height = '0';
}
function enter(el) {
	setOptions(el.scrollHeight, true);
	nextTick(() => {
		el.style.height = `${el.scrollHeight}px`;
	});
}
function afterEnter(el) {
	el.style.height = 'auto';
}
function beforeLeave(el) {
	setOptions(el.scrollHeight, false);
	el.style.height = `${el.scrollHeight}px`;
}
function leave(el) {
	el.style.height = '0';
}
function setOptions(height, isEnter) {
	if (height < 500) {
		duration.value = 0.4;
	} else if (height < 1000) {
		duration.value = 0.5;
	} else if (height < 5000) {
		duration.value = 0.7;
	} else if (height < 10000) {
		duration.value = 1;
	} else {
		duration.value = 1.3;
	}
	duration.value = `${duration.value}s`;

	if (height >= 3000) {
		isEnter ? timing.value = 'ease-in' : timing.value = 'ease-out';
	} else {
		timing.value = 'ease';
	}
}
</script>

<style scoped>
.toggle-height-enter-active,
.toggle-height-leave-active {
	transition: height v-bind('duration') v-bind('timing');
}

.animate-height {
	will-change: height;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
	overflow: hidden;
}
</style>
