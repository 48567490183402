import { url as urlHelper } from '@/helpers';
import { clearDatabase, getDb } from '@/idbInit';
import User from '@/models/User';
import { addCompanyIdToUrl, API_USER_SELF_CACHE_NAME, fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Get all users, filtered by the following parameters.
	 * @param {Object} params
	 * @param {boolean} params.active
	 * @returns (async) Returns a Response if the request was made without network errors, or undefined if a network error occurred.
	 */
	async getAll({ active = undefined } = {}) {
		const query = {};
		if (typeof active === 'boolean') {
			query.active = active;
		}
		const url = urlHelper('/api/users', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			const idb = await getDb();
			let data = await idb.getAll('users');
			if (typeof active === 'boolean') {
				data = data.filter(x => x.active === active);
			}
			return data.map(x => new User(x));
		}
		if (response.ok) {
			const data = await response.json();
			const idb = await getDb();
			if (active === undefined) {
				await idb.clear('users');
			}
			for (let i = 0; i < data.length; i++) {
				await idb.put('users', data[i], data[i].id);
			}
			return data.map(x => new User(x));
		} else {
			return response;
		}
	},
	/**
	 * Get a user
	 * @param {id} User User ID
	 * @returns (async) Returns a User if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/users/' + id);
		} catch {
			const idb = await getDb();
			const data = await idb.get('users', id);
			return data ? new User(data) : offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			const idb = await getDb();
			await idb.put('users', data, data.id);
			return new User(data);
		} else {
			return response;
		}
	},
	/**
	 * Create a user
	 * @param {model} User user to create.
	 * @returns (async) Returns the new User if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/users/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			const idb = await getDb();
			await idb.put('users', data, data.id);
			return new User(data);
		} else {
			return response;
		}
	},
	/**
	 * Update a use
	 * @param {model} User user to update.
	 * @returns (async) Returns the updated User if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/users/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = JSON.parse(JSON.stringify(model));
			const idb = await getDb();
			await idb.put('users', data, data.id);
			return new User(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete a user
	 * @param {id} Number User ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the user could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/users/' + id, { method: 'DELETE' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok || response.status === 404) {
			const idb = await getDb();
			await idb.delete('users', id);
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	},
	async resendInvite(id) {
		let response;
		try {
			response = await fetchWrap('/api/users/' + id + '/resendsetupemail', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return true;
		} else {
			return response;
		}
	},
	async sendPendindInvites() {
		let response;
		try {
			response = await fetchWrap('/api/users/sendsetuppendingemails', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return true;
		} else {
			return response;
		}
	},
	async getSelf() {
		let response, data;
		const request = new Request(addCompanyIdToUrl('/api/users/self'));
		try {
			response = await fetch(request);
			if (response.ok) {
				data = await response.json();
				if (!(data.id > 0) || !(data.companyId > 0)) {
					await clearDatabase();
				}
			}
		} catch {
			// directly check the cache so this API call works when called from the service worker while offline
			try {
				const cache = await caches.open(API_USER_SELF_CACHE_NAME);
				response = await cache.match(request, { ignoreMethod: true });
				if (!response) { response = offlineResponse(); }
				if (response.ok) { data = await response.json(); }
			} catch (e) {
				console.error(e);
				response = offlineResponse();
			}
		}
		return data ? new User(data) : null;
	},
	async login(model) {
		await clearDatabase();
		let response;
		try {
			response = await fetch('/api/users/login', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async logout() {
		let response;
		try {
			response = await fetch('/api/users/logout', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await clearDatabase();
			const broadcast = new BroadcastChannel('auth');
			broadcast.postMessage({ type: 'logout', });
			broadcast.close();

			var cookies = document.cookie.split(";");
	
			for (let i = 0; i < cookies.length; i++) {
				var spcook =  cookies[i].split("=");
				var d = new Date();
				d.setDate(d.getDate() - 1);
				var expires = ";expires="+d;
				var name=spcook[0];
				var value="";
				document.cookie = name + "=" + value + expires + "; path=/";
			}
		}
		return response;
	},
	async requestPasswordReset(model) {
		await clearDatabase();
		let response;
		try {
			response = await fetch('/api/users/forgotpassword', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async resetPassword(model) {
		await clearDatabase();
		let response;
		try {
			response = await fetch('/api/users/resetpassword', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async updateSelf(model) {
		let response, data;
		const request = new Request(addCompanyIdToUrl('/api/users/self'), {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(model),
		});
		try {
			response = await fetch(request);
			if (response.ok) {
				data = await response.json();
			} else if (response.status === 401) {
				await clearDatabase();
			}
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new User(data);
		}
		return response;
	},
	async changePassword(model) {
		let response;
		try {
			response = await fetch('/api/users/changepassword', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.status === 401) {
			await clearDatabase();
		}
		return response;
	},
	async setup(model) {
		await clearDatabase();
		let response;
		try {
			response = await fetch('/api/users/setup', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async usageReport(startDate, endDate) {
		const query = {startDate, endDate};
		const url = urlHelper('/api/users/usagereport', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		return response;
	},
};
