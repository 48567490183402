import { url as urlHelper } from '@/helpers';
import { getDb } from '@/idbInit';
import * as localChanges from '@/idbLocalChanges';
import * as localIds from '@/idbLocalIds';
import Lead from '@/models/Lead';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import PaginatedList from '@/models/PaginatedList';
import { DateTime } from 'luxon';
import { abortedResponse, fetchWrap, offlineResponse } from '../_helpers';

function compareLeadsByName(a, b) {
	if (a.lastName !== b.lastName) {
		return a.lastName < b.lastName ? -1 : 1;
	} else if (a.firstName !== b.firstName) {
		return a.firstName < b.firstName ? -1 : 1;
	} else {
		return 0;
	}
}
function compareLeadsByCompany(a, b) {
	if (a.company !== b.company) {
		return a.company < b.company ? -1 : 1;
	} else {
		return 0;
	}
}
function compareLeadsByMeetingDate(a, b) {
	const fallback = DateTime.fromObject({ year: 9999 });
	const aDate = a.meetingDate ? a.meetingDate : fallback;
	const bDate = b.meetingDate ? b.meetingDate : fallback;
	return aDate - bDate;
}

export default {
	getComparator(sort) {
		switch (sort) {
			case 'name-asc':
				return compareLeadsByName;
			case 'name-desc':
				return (a, b) => compareLeadsByName(b, a);
			case 'company-asc':
				return compareLeadsByCompany;
			case 'company-desc':
				return (a, b) => compareLeadsByCompany(b, a);
			case 'meeting-asc':
				return compareLeadsByMeetingDate;
			case 'meeting-desc':
				return (a, b) => compareLeadsByMeetingDate(b, a);
			default:
				return () => 0;
		}
	},
	/**
	 * Get a paginated list of leads, filtered by the following parameters.
	 * @param {Object} params
	 * @param {string} params.searchQuery required
	 * @param {number} params.userId optional
	 * @param {DateTime} params.startDate optional
	 * @param {DateTime} params.endDate optional
	 * @param {number} params.limit optional
	 * @param {number} params.offset optional
	 * @returns (async) Returns a PaginatedList of Leads if the request was successful, otherwise a Response.
	*/
	async getPaged({ searchQuery = undefined, userId = undefined, startDate = undefined, endDate = undefined, limit = undefined, offset = undefined, sort = undefined } = {}, abortSignal) {
		const allowedSorts = ['name-asc', 'name-desc', 'company-asc', 'company-desc', 'meeting-asc', 'meeting-desc'];
		let path = '/api/leads';
		const query = {
			limit: 100,
			offset: 0,
		};
		const isSearch = typeof searchQuery === 'string' && searchQuery;
		if (isSearch) {
			query.searchQuery = searchQuery;
			path = '/api/leads/search';
			allowedSorts.unshift('relevance');
		}
		query.userId = 0; // any userId
		if (userId === null || typeof userId === 'number') {
			query.userId = userId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		if (typeof limit === 'number' && limit >= 1 && limit <= 100) {
			query.limit = limit;
		}
		if (typeof offset === 'number' && limit >= 0) {
			query.offset = offset;
		}
		if (typeof sort === 'string' && allowedSorts.includes(sort)) {
			query.sort = sort;
		} else {
			query.sort = allowedSorts[0];
		}
		const url = urlHelper(path, query);
		let response;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
		} catch (e) {
			if (e instanceof DOMException && e.name === 'AbortError') {
				return abortedResponse();
			} else if (isSearch) {
				return offlineResponse();
			}
			const idb = await getDb();
			const data = await idb.getAll('leads');
			const model = new PaginatedList();
			// filter, sort, and paginate data from idb
			model.data = data
				.map(x => new Lead(x))
				.filter(x => {
					let include = true;
					// userId 0 means any user
					include = include && (query.userId === 0 || x.userId === query.userId);
					include = include && (!(startDate instanceof DateTime) || x.meetingDate >= startDate);
					include = include && (!(endDate instanceof DateTime) || x.meetingDate < endDate);
					return include;
				})
				.sort(this.getComparator(query.sort))
				.slice(query.offset, query.offset + query.limit);
			return model;
		}
		if (response.ok) {
			const data = await response.json();
			const idb = await getDb();
			for (let i = 0; i < data.data.length; i++) {
				await idb.put('leads', data.data[i], data.data[i].id);
			}
			const model = new PaginatedList(data);
			model.data = data.data.map(x => new Lead(x));
			return model;
		} else {
			return response;
		}
	},
	/**
	 * Get a lead
	 * @param {id} Number lead ID
	 * @returns (async) Returns a Lead if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/leads/' + id);
		} catch {
			const idb = await getDb();
			const data = await idb.get('leads', id);
			return data ? new Lead(data) : offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			const idb = await getDb();
			await idb.put('leads', data, data.id);
			return new Lead(data);
		} else {
			return response;
		}
	},
	/**
	 * Get a list of PONumber for a lead
	 * @param {id} Number lead ID
	 * @returns (async) Returns a list of PONumbers if the request was successful, otherwise a Response.
	 */
	async getPONumbers(id) {
		let response;
		try {
			response = await fetchWrap('/api/leads/' + id + '/projects');
		} catch {
			response.ok = false;
		}
		if (response.ok) {
			const data = await response.json();
			return data;
		} else {
			return response;
		}
	},
	/**
	 * Create a lead
	 * @param {model} Lead lead to create.
	 * @returns (async) Returns the new Lead if the request was successful, otherwise a Response.
	 */
	async create(model) {
		const requestBody = JSON.stringify(model);
		let response;
		try {
			response = await fetchWrap('/api/leads/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: requestBody,
			});
		} catch {
			const data = JSON.parse(requestBody);
			const idb = await getDb();
			if (data.id === 0) {
				data.id = await localIds.getNewId(idb, 'leads');
			}
			await idb.put('leads', data, data.id);
			await localChanges.add(idb, new LocalChange({ storeName: 'leads', id: data.id, state: LocalChangeState.added }));
			return new Lead(data);
		}
		if (response.ok) {
			const data = await response.json();
			const idb = await getDb();
			await localIds.addLocalIdMap(idb, 'leads', model.id, data.id);
			await idb.delete('leads', model.id);
			await idb.put('leads', data, data.id);
			await localChanges.deleteChange(idb, LocalChange.getKey('leads', model.id));
			return new Lead(data);
		} else {
			return response;
		}
	},
	/**
	 * Update a lead
	 * @param {model} Lead lead to update.
	 * @returns (async) Returns the updated Lead if the request was successful, otherwise a Response.
	 */
	async update(model) {
		const idb = await getDb();
		model.id = await localIds.mapLocalId(idb, 'leads', model.id);
		const requestBody = JSON.stringify(model);
		let response;
		try {
			response = await fetchWrap('/api/leads/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: requestBody,
			});
		} catch {
			const data = JSON.parse(requestBody);
			await idb.put('leads', data, data.id);
			await localChanges.add(idb, new LocalChange({ storeName: 'leads', id: data.id, state: LocalChangeState.modified }));
			return new Lead(data);
		}
		if (response.ok) {
			const data = JSON.parse(requestBody);
			await idb.put('leads', data, data.id);
			await localChanges.deleteChange(idb, LocalChange.getKey('leads', model.id));
			return new Lead(data);
		} else {
			return response;
		}
	},
	/**
	 * Update a lead
	 * @param {model} Lead lead to update.
	 * @returns (async) Returns the updated Lead if the request was successful, otherwise a Response.
	 */
	async setHoverJobId(leadId, jobId) {
		const idb = await getDb();
		leadId = await localIds.mapLocalId(idb, 'leads', leadId);
		const url = urlHelper('/api/leads/' + leadId + '/SetHoverJobId', { jobId });
		let response;
		try {
			response = await fetchWrap(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
			});
		} catch {
			return offlineResponse();
		}
		// TODO: handle case when this lead has a local change not yet synced
		// (current implementation will discard those unsynced changes)
		if (response.ok) {
			const data = await response.json();
			await idb.put('leads', data, data.id);
			await localChanges.deleteChange(idb, LocalChange.getKey('leads', leadId));
			return new Lead(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete a lead
	 * @param {id} Number Lead ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the lead could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		const idb = await getDb();
		id = await localIds.mapLocalId(idb, 'leads', id);
		let response;
		try {
			response = await fetchWrap('/api/leads/' + id, { method: 'DELETE' });
		} catch {
			const model = await idb.get('leads', id);
			if (!model) { return offlineResponse(); }
			// an imported lead cannot be deleted by the user
			let cannotDelete = !!model.externalId;
			// a lead cannot be deleted if it has an estimate
			cannotDelete = cannotDelete || (await idb.countFromIndex('estimates', 'leadId', id)) > 0;
			if (cannotDelete) {
				return false;
			} else {
				await idb.delete('leads', id);
				await localChanges.add(idb, new LocalChange({ storeName: 'leads', id: id, state: LocalChangeState.deleted }));
				return true;
			}
		}
		if (response.ok || response.status === 404) {
			await idb.delete('leads', id);
			await localChanges.deleteChange(idb, LocalChange.getKey('leads', id));
			return true;
		} else if (response.status === 409) {
			await localChanges.deleteChange(idb, LocalChange.getKey('leads', id));
			return false;
		} else {
			return response;
		}
	}
};
