<template>
	<label class="nowrap" :class="{ 'char-limit': labelLength > 40 }" :for="htmlFieldName">
		{{ title ?? '' }}
		<span v-if="description" class="label-small descrip">({{ description }})</span>
	</label>
</template>

<script setup>
import { computed, inject } from 'vue';

const props = defineProps({
	title: {
		type: String,
		required: false,
	},
	description: {
		type: String,
		required: false,
	},
	name: {
		type: String,
		required: true,
	},
});
const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const labelLength = computed(() => (props.title ? props.title.length : 0) + (props.description ? props.description.length : 0));
</script>
