import $router from '@/router/index';
import $store from '@/store/index';
import MessageType from '@/models/MessageType';

export default {
	GENERIC_HTTP_ERROR: 'A problem has occurred. Please refresh the page and try again. If the problem persists, contact support.',
	/**
	 * Handle common HTTP fetch repsponse status codes
	 * @param {Response} response
	 */
	async handleHttpError(response, redirectUrl) {
		const status = response.status;
		if (status >= 100 && status <= 299) {
			// info and successful responses
			return;
		} else if (status === 401) {
			// unauthenticated - user must log in
			await $store.dispatch('refresh');
			await $store.dispatch('addMessage', { message: 'Your session has expired, please login again.', type: MessageType.error });
			return $router.push('/');
		} else if (status === 403) {
			// forbidden - logged in, action not permitted
			$store.dispatch('addMessage', { message: 'Access denied. Your account does not have permission to do this.', type: MessageType.error });
		} else if (status === 404 || status === 410) {
			// not found
			$store.dispatch('addMessage', { message: 'The information you requested was not found.', type: MessageType.error });
		} else if (status === 408) {
			// timeout
			$store.dispatch('addMessage', { message: 'The request has timed out because the server took too long to respond.', type: MessageType.error });
		} else if (status === 502 || status === 503 || status === 504) {
			// offline / server not available
			$store.dispatch('addMessage', { message: 'Your device is offline or the server is unavailable right now.', type: MessageType.error });
		} else {
			// treat all other responses as general errors
			$store.dispatch('addMessage', { message: this.GENERIC_HTTP_ERROR, type: MessageType.error });
		}
		if (redirectUrl) {
			return $router.push(redirectUrl);
		}
	},
	async processValidationErrors(response, keys) {
		const responseData = await response.json();
		this.cleanErrors(responseData);
		const serverErrors = {};
		for (let i = 0; i < keys.length; i++) {
			serverErrors[keys[i]] = undefined;
		}
		if (typeof responseData === 'object') {
			if (responseData.title !== this.GENERIC_VALIDATION_ERROR) {
				$store.dispatch('addMessage', { message: responseData.title, type: MessageType.error });
			}
			if ('errors' in responseData && typeof responseData.errors === 'object') {
				for (const key in responseData.errors) {
					if (Object.hasOwnProperty.call(responseData.errors, key)) {
						const message = responseData.errors[key].join(' ');
						if (keys.includes(key)) {
							serverErrors[key] = message;
						} else {
							$store.dispatch('addMessage', { message: message, type: MessageType.error });
						}
					}
				}
			}
		} else {
			$store.dispatch('addMessage', { message: this.GENERIC_VALIDATION_ERROR, type: MessageType.error });
		}
		return serverErrors;
	},
	GENERIC_VALIDATION_ERROR: 'One or more validation errors occurred.',
	cleanErrors(responseData) {
		/* useful: {title,detail,errors?}, less useful: {type,status,instance} */
		/* errors is an object of key:array */
		if (responseData.errors && typeof responseData.errors === 'object') {
			// System.Text.Json version
			// let jsonDetailPattern = / Path: \$\.\w+ \| LineNumber: \d+ \| BytePositionInLine: \d+\.$/i;
			// Newtonsoft.Json version
			const jsonDetailPattern = / Path '[^']+', Line \d+, Position \d+\.$/i;
			// Path 'contacts[0].emailAddresses[1].sortOrder', line 1, position 1213.
			for (const key in responseData.errors) {
				if (Object.hasOwnProperty.call(responseData.errors, key)) {
					const element = responseData.errors[key];
					for (let i = 0; i < element.length; i++) {
						element[i] = element[i].replace(jsonDetailPattern, '');
					}
				}
			}
			const originalKeys = Object.keys(responseData.errors);
			for (let i = 0; i < originalKeys.length; i++) {
				const key = originalKeys[i];
				if (Object.hasOwnProperty.call(responseData.errors, key)) {
					// cleanup key
					let newKey = key.startsWith('$.') ? key.substring(2) : key;
					newKey = newKey.substr(0, 1).toLowerCase() + newKey.substr(1);
					let dotIndex = newKey.indexOf('.');
					while (dotIndex >= 0 && dotIndex + 1 < newKey.length) {
						newKey = newKey.substr(0, dotIndex + 1) + newKey.substr(dotIndex + 1, 1).toLowerCase() + newKey.substr(dotIndex + 2);
						dotIndex = newKey.indexOf('.', dotIndex + 1);
					}
					if (newKey !== key) {
						if (Object.hasOwnProperty.call(responseData.errors, newKey)) {
							responseData.errors[newKey] = responseData.errors[newKey].concat(responseData.errors[key]);
						} else {
							responseData.errors[newKey] = responseData.errors[key];
						}
						delete responseData.errors[key];
					}
				}
			}
		}
	},
};
