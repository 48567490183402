<template>
	<teleport to="body">
		<transition name="pwa-toggle" appear>
			<div v-if="open" class="pwa-install-banner">
				<div class="inner">
					<div class="icon-prompt-text">
						<img src="@/img/icons/app-icon.png" alt="" />
						<div class="ml-1">Install IHS Home Builder to your home screen?</div>
					</div>
					<div class="answer-buttons">
						<button @click="showPwaInstall()" class="btn btn-fill-app mb-quarter no-min">Yes</button>
						<button @click="closeBanner()" class="btn-link ml-1-half no-min">No</button>
					</div>
				</div>
			</div>
		</transition>
	</teleport>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const $store = useStore();
const open = computed(() => $store.state.showInstallBanner && !$store.state.serviceWorkerUpdate);

function closeBanner() {
	$store.commit('setCustomPwaBanner', false);
	localStorage.setItem('pwaUserChoice', 'no');
}
function showPwaInstall() {
	$store.commit('setCustomPwaBanner', false);
	$store.state.installPrompt.prompt();

	$store.state.installPrompt.userChoice.then((choiceResult) => {
		if (choiceResult.outcome === 'accepted') {
			$store.commit('setPwaInstallButton', false);
		} else {
			localStorage.setItem('pwaUserChoice', 'no');
		}
	});
}
</script>
