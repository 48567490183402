import DocumentSignature from './DocumentSignature';
import { getArrayFromDto, getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helper';

export class DocumentType {
	static contract = 1;
	static purchaseOrder = 2;
	static workOrder = 3;
	static costEstimate = 4;
	static laborBill = 5;
	static finalLaborBill = 8;
	static itemizedEstimate = 6;
	static changeOrder = 7;

	static options = [
		{ id: this.contract, name: 'contract', },
		{ id: this.purchaseOrder, name: 'purchase order', },
		{ id: this.workOrder, name: 'work order', },
		{ id: this.costEstimate, name: 'cost estimate', },
		{ id: this.laborBill, name: 'labor bill', },
		{ id: this.finalLaborBill, name: 'final labor bill', },
		{ id: this.itemizedEstimate, name: 'itemized estimate', },
		{ id: this.changeOrder, name: 'change order', },
	]

	static getName(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}
}

export class PdfStatus {
	static unknown = 0;
	static success = 1;
	static failure = 2;
	static skipped = 3;
	static queued = 5;
	static processing = 4;

	static options = [
		{ id: this.unknown, name: 'unknown', },
		{ id: this.success, name: 'success', },
		{ id: this.failure, name: 'failure', },
		{ id: this.skipped, name: 'skipped', },
		{ id: this.queued, name: 'queued', },
		{ id: this.processing, name: 'processing', },
	]

	static getName(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}
}

export class DocumentReference {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.estimateId = getValue(dto, 'estimateId', 'number', null);
		this.leadId = getValue(dto, 'leadId', 'number', null);
		this.createdByUserId = getValue(dto, 'createdByUserId', 'number', null);
		this.name = getValue(dto, 'name', 'string', '');
		this.pdfStatus = getValue(dto, 'pdfStatus', 'number', PdfStatus.queued);
		this.pdfUrl = getValue(dto, 'pdfUrl', 'string', '');
		this.type = getValue(dto, 'type', 'number', DocumentType.contract);
		this.workflows = getArrayFromDto(dto, 'workflows', []);
		this.createdTime = getDate(dto, 'createdTime', null);
	}

	getViewUrl(companyId) {
		if (this.pdfUrl) return this.pdfUrl;
		switch (this.type) {
			case DocumentType.contract: return `/pdf/contract/${this.id}?companyId=${companyId}`;
			case DocumentType.changeOrder: return `/pdf/change-order/${this.id}?companyId=${companyId}`;
			case DocumentType.purchaseOrder: return `/pdf/purchase-order/${this.id}?companyId=${companyId}`;
			case DocumentType.workOrder: return `/pdf/work-order/${this.id}?companyId=${companyId}`;
			case DocumentType.costEstimate: return `/pdf/cost-estimate/${this.id}?companyId=${companyId}`;
			case DocumentType.laborBill: return `/pdf/labor-bill/${this.id}?companyId=${companyId}`;
			case DocumentType.finalLaborBill: return `/pdf/final-labor-bill/${this.id}?companyId=${companyId}`;
			case DocumentType.itemizedEstimate: return `/pdf/itemized-estimate/${this.id}?companyId=${companyId}`;
			default: return undefined;
		}
	}
}

export class Document extends DocumentReference {
	constructor(dto) {
		super(dto);
		this.data = {};
		this.signatures = {};

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.data === 'object') {
				Object.assign(this.data, dto.data);
			}
			if (typeof dto.signatures === 'object') {
				for (const key in dto.signatures) {
					if (Object.hasOwnProperty.call(dto.signatures, key)) {
						this.signatures[key] = new DocumentSignature(dto.signatures[key]);
					}
				}
			}
		}
	}

	get typeVersion() {
		let version = parseInt(this.data.typeVersion);
		if (isNaN(version)) { version = 1; }
		return version;
	}

	clone() {
		return new Document(JSON.parse(JSON.stringify(this)));
	}
}
