import { getDb } from '@/idbInit';
import * as localChanges from '@/idbLocalChanges';
import { Document } from '@/models/Document';
import Estimate from '@/models/Estimate';
import Lead from '@/models/Lead';
import LocalChangeState from '@/models/LocalChangeState';
import $store from '@/store/index';
import documentsApi from './documents';
import estimatesApi from './estimates';
import leadsApi from './leads';

export default {
	async syncOfflineChanges() {
		if ($store.state.syncing) { return; }
		$store.commit('setSyncing', true);
		const idb = await getDb();
		try {
			// delete documents, then estimates, then leads
			let changes = await localChanges.getAll(idb, 'estimate-documents', LocalChangeState.deleted);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				const response = await documentsApi.delete(change.data);
				if (response instanceof Response) {
					change.error = response.status;
					await localChanges.add(idb, change);
				}
			}
			changes = await localChanges.getAll(idb, 'estimates', LocalChangeState.deleted);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				const response = await estimatesApi.deleteById(change.id);
				if (response instanceof Response) {
					change.error = response.status;
					await localChanges.add(idb, change);
				}
			}
			changes = await localChanges.getAll(idb, 'leads', LocalChangeState.deleted);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				const response = await leadsApi.deleteById(change.id);
				if (response instanceof Response) {
					change.error = response.status;
					await localChanges.add(idb, change);
				}
			}
			// update leads then estimates
			changes = await localChanges.getAll(idb, 'leads', LocalChangeState.modified);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				const model = await idb.get('leads', change.id)
				if (model) {
					const response = await leadsApi.update(model);
					if (response instanceof Response) {
						change.error = response.status;
						await localChanges.add(idb, change);
					}
				} else {
					await localChanges.deleteChange(idb, change.key);
				}
			}
			changes = await localChanges.getAll(idb, 'estimates', LocalChangeState.modified);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				const model = await idb.get('estimates', change.id)
				if (model) {
					const response = await estimatesApi.update(model);
					if (response instanceof Response) {
						change.error = response.status;
						await localChanges.add(idb, change);
					}
				} else {
					await localChanges.deleteChange(idb, change.key);
				}
			}
			// create leads, then estimates, then documents
			changes = await localChanges.getAll(idb, 'leads', LocalChangeState.added);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				const model = await idb.get('leads', change.id)
				if (model) {
					const response = await leadsApi.create(new Lead(model));
					if (response instanceof Response) {
						change.error = response.status;
						await localChanges.add(idb, change);
					} else {
						// find estimates and documents that have this negative id and update them to the new id
						const estimates = await idb.getAllFromIndex('estimates', 'leadId', change.id)
						for (let j = 0; j < estimates.length; j++) {
							const estimate = estimates[j];
							estimate.leadId = response.id;
							await idb.put('estimates', estimate, estimate.id);
						}
						const documents = await idb.getAllFromIndex('estimate-documents', 'leadId', change.id)
						for (let j = 0; j < documents.length; j++) {
							const document = documents[j];
							document.leadId = response.id;
							await idb.put('estimate-documents', document, document.id);
						}
					}
				} else {
					await localChanges.deleteChange(idb, change.key);
				}
			}
			changes = await localChanges.getAll(idb, 'estimates', LocalChangeState.added);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				const model = await idb.get('estimates', change.id)
				if (model) {
					const response = await estimatesApi.create(new Estimate(model));
					if (response instanceof Response) {
						change.error = response.status;
						await localChanges.add(idb, change);
					} else {
						// find documents that have this negative id and update them to the new id
						const documents = await idb.getAllFromIndex('estimate-documents', 'estimateId', change.id)
						for (let j = 0; j < documents.length; j++) {
							const document = documents[j];
							document.estimateId = response.id;
							await idb.put('estimate-documents', document, document.id);
						}
					}
				} else {
					await localChanges.deleteChange(idb, change.key);
				}
			}
			changes = await localChanges.getAll(idb, 'estimate-documents', LocalChangeState.added);
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				if (change.error) { continue; }
				let model = await idb.get('estimate-documents', change.id)
				if (model) {
					model = new Document(model);
					for (const key in model.signatures) {
						if (Object.hasOwnProperty.call(model.signatures, key)) {
							const signature = model.signatures[key];
							if (signature.url) {
								signature.data = await idb.get('estimate-document-signatures', signature.url);
							}
						}
					}
					const response = await documentsApi.create(model);
					if (response instanceof Response) {
						change.error = response.status;
						await localChanges.add(idb, change);
					}
				} else {
					await localChanges.deleteChange(idb, change.key);
				}
			}
		} catch (e) {
			console.error(e);
		}
		$store.commit('setSyncing', false);
	}
};
