import companyId from "./modules/companyId";

const APP_URL_ORIGIN = self.location.origin;
export const API_USER_SELF_CACHE_NAME = 'ihs-user-self-cache';
export const API_IMAGE_CACHE_NAME = 'ihs-images-cache';

export function offlineResponse() {
	return new Response(null, { status: 503, statusText: 'Offline' });
}

export function isOfflineResponse(response) {
	return response instanceof Response && response.status === 503 && response.statusText === 'Offline';
}

export function abortedResponse() {
	return new Response(null, { status: 418, statusText: 'Request aborted' });
}

export function isAbortedResponse(response) {
	return response instanceof Response && response.status === 418 && response.statusText === 'Request aborted';
}

export function notFoundResponse() {
	return new Response(null, { status: 404, statusText: 'Not Found' });
}

/**
 *
 * @param {string|URL} url
 * @param {*} init
 * @returns {Promise<Response>} Fetch API response
 */
export function fetchWrap(url, init) {
	url = addCompanyIdToUrl(url);
	return fetch(url, init);
}

export function addCompanyIdToUrl(url) {
	const _companyId = companyId.get();
	if (_companyId) {
		url = new URL( url, APP_URL_ORIGIN);
		url.searchParams.set('companyId', _companyId);
	}
	return url;
}
