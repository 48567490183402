<template>
	<div class="loading-animate-contain">
		<transition name="toggle-overlay" appear>
			<div class="loading-overlay"></div>
		</transition>
		<transition name="toggle-animate" appear>
			<div class="loading-animate">
				<img v-if="action === 'send'" src="@/img/sending.gif" alt="" />
				<img v-else src="@/img/creating.gif" alt="" />
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		action: {
			type: String,
			required: false,
		},
	},
}
</script>

