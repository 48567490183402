import { onMounted, onUnmounted, ref } from 'vue';

export function useDragAndDrop() {
	const dragging = ref(false);
	const dropAreaListeners = {
		dragenter: [preventDefaults, highlight],
		dragover: [preventDefaults, highlight],
		dragleave: [preventDefaults, unhighlight],
		drop: [preventDefaults, unhighlight, handleDrop],
	};
	function preventDefaults(e) {
		e.preventDefault();
		e.stopPropagation();
	}
	function highlight() {
		dragging.value = true;
	}
	function unhighlight() {
		dragging.value = false;
	}

	let dropHandlers = [];
	function handleDrop(event) {
		if (event.dataTransfer.files.length > 0) {
			for (let i = 0; i < dropHandlers.length; i++) {
				dropHandlers[i].call(undefined, event.dataTransfer.files);
			}
		}
	}

	function addDropHandler(handler) {
		if (typeof handler === 'function') {
			dropHandlers.push(handler);
		}
	}

	onMounted(() => {
		// Prevent default drag behaviors
		['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
			document.body.addEventListener(eventName, preventDefaults, false);
		});
	});

	onUnmounted(() => {
		// cleanup event listeners
		['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
			document.body.removeEventListener(eventName, preventDefaults);
		});
	});

	return {
		dragging,
		dropAreaListeners,
		addDropHandler,
	};
}
