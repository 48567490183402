import companyId from '@/api/modules/companyId';
import { DateTime } from 'luxon';
import { getDateTimeFromDto as getDate, getValueFromDto as getValue, hasDtoProp } from './_helper';

export default class FileReference {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'string', FileReference.DEFAULT_ID);
		Object.defineProperty(this, 'data', { enumerable: false, value: hasDtoProp(dto, 'data') ? dto.data : null, writable: true });
		Object.defineProperty(this, 'name', { enumerable: false, value: getValue(dto, 'name', 'string', ''), writable: true });
		Object.defineProperty(this, 'size', { enumerable: false, value: getValue(dto, 'size', 'number', 0), writable: true });
		Object.defineProperty(this, 'uploadTimestamp', { enumerable: false, value: getDate(dto, 'uploadTimestamp', DateTime.now()), writable: true });
		Object.defineProperty(this, 'url', { enumerable: false, value: getValue(dto, 'url', 'string', ''), writable: true });
	}

	get extension() {
		if (this.name) {
			const index = this.name.lastIndexOf('.');
			if (index >= 0 && index < this.name.length - 1) {
				return this.name.substr(index).toLowerCase();
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	static DEFAULT_ID = '0000000000000000000000000000000000000000000000000000000000000000';

	static getUrl(id) {
		if (id && id !== this.DEFAULT_ID) {
			return '/api/Files/Download/' + id + '?companyId=' + companyId.get();
		} else {
			return '';
		}
	}
}
