<template>
	<button
		type="button"
		@click="$emit('update:modelValue', !modelValue, object)"
		:disabled="disabled"
		:class="{ checked: modelValue && !loading, 'ui-check' : !isSlider, 'ui-slider': isSlider }"
	>
		<span class="loading" v-if="loading"></span>
	</button>
</template>

<script>
export default {
	emits: ['update:modelValue'],
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		isSlider: {
			type: Boolean,
			required: false,
			default: false,
		},
		object: {
			type: Object,
			required: false,
		},
	},
};
</script>
