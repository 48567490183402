export default {
	// The object is not being tracked.
	// detached: 0,
	// The object is being tracked and exists in the database. Its property values have not changed from the values in the database.
	unchanged: 1,
	// The object is being tracked and exists in the database. It has been marked for deletion from the database.
	deleted: 2,
	// The object is being tracked and exists in the database. Some or all of its property values have been modified.
	modified: 3,
	// The object is being tracked but does not yet exist in the database.
	added: 4,
};
