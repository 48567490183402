import api from '@/api/index';
import companyId from '@/api/modules/companyId';
import { getAppInsights } from '@/applicationInsights';
import User from '@/models/User';

// initial state
const state = () => ({
	user: new User(),
});

// getters
const getters = {
	isLoggedIn: (state) => state.user !== null && state.user.id > 0 && (state.user.companyId === null || state.user.active),
	isSuperAdmin: (state, getters) => getters.isLoggedIn && state.user.isSuperAdmin,
	isAdmin: (state, getters) => getters.isUser && (state.user.role === 'Admin' || getters.isSuperAdmin),
	isUser: (state, getters) => getters.isLoggedIn && state.user.companyId > 0,
	isCompanyUser: (state, getters) => getters.isLoggedIn && state.user.companyId > 0 && (state.user.role === 'User' || state.user.role === 'Admin'),
};

// mutations
const mutations = {
	setUser(state, user) {
		if (user !== null && user instanceof User) {
			state.user = user;
		} else {
			state.user = new User();
		}
		const appInsights = getAppInsights();
		if (appInsights) {
			const userId = state.user.id > 0 ? state.user.id : '';
			const companyId = state.user.companyId > 0 ? state.user.companyId : '';
			appInsights.setAuthenticatedUserContext(userId, companyId, false);
		}
	},
};

// actions
const actions = {
	async clear({ commit }) {
		commit('setUser', null);
	},
	async refresh({ commit }) {
		await companyId.setFromIdb();
		const user = await api.users.getSelf();
		commit('setUser', user);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
