<template>
	<div class="package-drop-area" v-on="dropAreaListeners">
		<input type="file" multiple :id="htmlFieldName" ref="fileInput" :accept="extensions.join() + ',' + mimeTypes.join()" class="hidden" @change="onFileChange" />

		<div class="form-grid file-input">
			<input-file-images-item
				v-for="(item, i) in model.items"
				:key="i"
				:index="i"
				:ref="'fileItem' + i"
				:name="name + `[${i}]`"
				:removeItem="model.removeItem"
				:constrain="constrain"
				:dragging="dragging"
			/>
			<div class="thumbnail-group">
				<label :for="htmlFieldName" class="package-thumbnail no-image" :class="{ highlight: dragging }">
					<div class="label" v-if="loading">Loading&hellip;</div>
					<div class="label" v-else>Drag or select image(s)</div>
					<svg width="24px" height="24px" class="icon fill-white stroke-green">
						<use xlink:href="#add"></use>
					</svg>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, ref } from 'vue';
	import { useStore } from 'vuex';
	import { isImageFile, resizeImage, previewImage } from '@/helpers';
	import api from '@/api/index';
	import { useDragAndDrop } from '@/composables/dragAndDrop';
	import FileReference from '@/models/FileReference';
	import MessageType from '@/models/MessageType';
	import { imageFileExtensions, imageFileMimeTypes } from '@/yupExtensions';
	import InputFileImagesItem from './InputFileImagesItem.vue';
	import { useField } from 'vee-validate';

	export default {
		props: {
			model: {
				type: Object,
				required: true,
			},
			name: {
				type: String,
				required: true,
			},
			setErrors: {
				type: Function,
				required: true,
			},
			id: {
				type: String,
				required: false,
				default: '',
			},
			constrain: {
				type: Boolean,
				required: false,
				default: false,
			},
		},
		components: {
			InputFileImagesItem,
		},
		setup(props) {
			const $store = useStore();
			const fileInput = ref(null);
			const loading = ref(false);

			const { value: modelValue } = useField(computed(() => props.name));
			const htmlFieldName = computed(() => (props.id ? props.id + '_' : '') + 'fileInput');

			async function save() {
				loading.value = true;
				for (let i = 0; i < modelValue.value.length; i++) {
					const file = modelValue.value[i];
					if (!file.data) {
						continue;
					}
					const response = await api.files.upload(file.data);
					if (response instanceof FileReference) {
						modelValue.value[i] = response;
					} else {
						if (response.status === 400) {
							const serverErrors = await api.helpers.processValidationErrors(response, ['formFile']);
							if (serverErrors.formFile) {
								props.setErrors({ [`${props.name}[${i}]`]: serverErrors.formFile });
							}
						} else {
							await api.helpers.handleHttpError(response);
						}
						loading.value = false;
						return false;
					}
				}
				loading.value = false;
				return true;
			}

			const onFileChange = async () => handleFiles(fileInput.value.files);

			async function handleFiles(files) {
				loading.value = true;
				for (let i = 0; i < files.length; i++) {
					let fileRef = null;
					let file = files[i];
					fileRef = new FileReference({
						data: file,
						name: file.name,
						size: file.size,
					});
					if (isImageFile(file)) {
						try {
							file = await resizeImage(file);
							fileRef = new FileReference({
								data: file,
								name: file.name,
								size: file.size,
								url: await previewImage(file),
							});
						} catch (e) {
							console.error(e);
							fileRef = null;
							$store.dispatch('addMessage', {
								message: 'Error processing image file: ' + e.message,
								type: MessageType.error,
							});
						}
					}
					if (fileRef) {
						props.model.addItem(fileRef);
					}
				}
				fileInput.value.value = '';
				loading.value = false;
			}

			// drag and drop
			const { dragging, dropAreaListeners, addDropHandler } = useDragAndDrop();
			addDropHandler(handleFiles);

			return {
				fileInput,
				loading,
				extensions: imageFileExtensions,
				mimeTypes: imageFileMimeTypes,
				htmlFieldName,
				dragging,
				dropAreaListeners,
				onFileChange,
				save,
			};
		},
	};
</script>
