<template>
	<div class="wysiwyg-contain">
		<div class="wysiwyg-header" v-if="editor">
			<div class="wysiwyg-heading" v-if="heading">{{ heading }}</div>
			<div class="wysiwyg-controls">
				<div class="control-group" v-if="mode === 'email'">
					<button type="button" @click="action().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
						<span class="text-icon">h1</span>
					</button>
					<button type="button" @click="action().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
						<span class="text-icon">h2</span>
					</button>
				</div>

				<div class="control-group" v-if="mode === 'checklist' || mode === 'scopeConfig'">
					<button type="button" @click="action().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
						<span class="text-icon">h2</span>
					</button>
					<button type="button" @click="action().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
						<span class="text-icon">h3</span>
					</button>
				</div>

				<div class="control-group">
					<button type="button" @click="action().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
						<svg class="icon" width="14px" height="14px">
							<use xlink:href="#wysiwyg-bold"></use>
						</svg>
					</button>
					<button type="button" @click="action().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
						<svg class="icon" width="14px" height="14px">
							<use xlink:href="#wysiwyg-italic"></use>
						</svg>
					</button>
					<button type="button" @click="action().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
						<svg class="icon" width="14px" height="14px">
							<use xlink:href="#wysiwyg-underline"></use>
						</svg>
					</button>
				</div>

				<div class="control-group" v-if="mode === 'checklist' || mode === 'scopeConfig'">
					<button type="button" @click="action().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }">
						<svg class="icon" width="15px" height="15px">
							<use xlink:href="#wysiwyg-check"></use>
						</svg>
					</button>
				</div>

				<div class="control-group" v-if="mode === 'standard' || mode === 'email'">
					<button type="button" @click="action().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
						<svg class="icon" width="19px" height="19px">
							<use xlink:href="#wysiwyg-bullet"></use>
						</svg>
					</button>
					<button type="button" @click="action().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
						<svg class="icon" width="19px" height="19px">
							<use xlink:href="#wysiwyg-ordered"></use>
						</svg>
					</button>
				</div>

				<div class="control-group">
					<button type="button" @click="action().undo().run()">
						<svg class="icon" width="15px" height="15px">
							<use xlink:href="#wysiwyg-undo"></use>
						</svg>
					</button>
					<button type="button" @click="action().redo().run()">
						<svg class="icon" width="15px" height="15px">
							<use xlink:href="#wysiwyg-redo"></use>
						</svg>
					</button>
				</div>
			</div>
		</div>
		<editor-content class="wysiwyg-editor" :editor="editor" />
	</div>
</template>

<script>
import { watch } from 'vue';
import { useEditor, EditorContent } from '@tiptap/vue-3';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import HardBreak from '@tiptap/extension-hard-break';
import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Linter, { Keywords } from '@/TipTapLinter';

export default {
	components: {
		EditorContent,
	},
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		mode: {
			type: String,
			required: false,
			default: 'standard',
		},
		heading: {
			type: String,
			required: false,
		},
	},
	emits: ['update:modelValue'],
	setup(props, context) {
		const extensions = [Document, Text, History, Bold, Italic, Underline, HardBreak, Paragraph];
		if (props.mode === 'standard') {
			extensions.push(BulletList);
			extensions.push(OrderedList);
			extensions.push(ListItem);
		} else if (props.mode === 'email') {
			extensions.push(BulletList);
			extensions.push(OrderedList);
			extensions.push(ListItem);
			extensions.push(Heading.configure({ levels: [1, 2] }));
		} else if (props.mode === 'scopeConfig') {
			extensions.push(TaskList);
			extensions.push(TaskItem.configure({ nested: false }));
			extensions.push(Heading.configure({ levels: [2, 3] }));
			extensions.push(Linter.configure({ plugins: [Keywords] }));
		} else if (props.mode === 'checklist') {
			extensions.push(TaskList);
			extensions.push(Heading.configure({ levels: [2, 3] }));
			extensions.push(TaskItem.configure({ nested: false }));
		}

		const editor = useEditor({
			extensions,
			content: props.modelValue,
			onUpdate: () => {
				if (editor.value.getHTML() === '<p></p>') {
					context.emit('update:modelValue', '');
				} else {
					context.emit('update:modelValue', editor.value.getHTML());
				}
			},
		});

		watch(
			() => props.modelValue,
			(value) => {
				if (editor.value.getHTML() !== value) {
					editor.value.commands.setContent(value, false);
				}
			}
		);

		const action = () => editor.value.chain().focus();

		return { editor, action };
	},
};
</script>
