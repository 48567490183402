<template>
	<rich-text-editor :id="htmlFieldName" v-model="value" :disabled="disabled" />
</template>

<script setup>
import { computed, inject, toRef } from 'vue';
import { useField } from 'vee-validate';
import RichTextEditor from '@/views/partials/RichTextEditor.vue';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});
const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const { value } = useField(toRef(props, 'name'));
</script>
