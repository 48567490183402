import { openDB } from 'idb';
import companyId from './api/modules/companyId';
import { API_IMAGE_CACHE_NAME, API_USER_SELF_CACHE_NAME } from './api/_helpers';

export async function getDb() {
	return await openDB('ihs-database', 6, {
		upgrade(db, oldVersion, newVersion, transaction) {
			// Initial setup
			if (oldVersion < 1) {
				db.createObjectStore('leads');
				const estimatesStore = db.createObjectStore('estimates');
				estimatesStore.createIndex("leadId", "leadId", { unique: false });
				db.createObjectStore('estimate-fields');
				db.createObjectStore('estimate-sections');
				db.createObjectStore('estimate-workflows');
			}

			if (oldVersion < 2) {
				db.createObjectStore('settings');
				db.createObjectStore('users');
			}

			if (oldVersion < 3) {
				db.createObjectStore('estimate-documents');
				db.createObjectStore('temp-ids');
				db.createObjectStore('changes');
			}
			if (oldVersion < 4) {
				const changesStore = transaction.objectStore('changes');
				changesStore.createIndex("store", "store", { unique: false });
			}
			if (oldVersion < 5) {
				db.deleteObjectStore('temp-ids');
				db.deleteObjectStore('changes');

				db.createObjectStore('estimate-document-signatures');
				const localChangesStore = db.createObjectStore('local-changes');
				localChangesStore.createIndex("storeName", "storeName", { unique: false });
				db.createObjectStore('local-ids');
				const localIdsMapStore = db.createObjectStore('local-ids-map');
				localIdsMapStore.createIndex("storeName", "storeName", { unique: false });

				const estimateDocumentsStore = transaction.objectStore('estimate-documents');
				estimateDocumentsStore.createIndex("estimateId", "estimateId", { unique: false });
				estimateDocumentsStore.createIndex("leadId", "leadId", { unique: false });
			}
			if (oldVersion < 6) {
				db.createObjectStore('info');
			}
		},
	});
}

export async function clearDatabase(skipCompanyIdReset) {
	const db = await getDb();
	if (!skipCompanyIdReset) {
		companyId.set(null);
	}
	await Promise.all([
		await db.clear('leads'),
		await db.clear('estimates'),
		await db.clear('estimate-fields'),
		await db.clear('estimate-sections'),
		await db.clear('estimate-workflows'),
		await db.clear('settings'),
		await db.clear('users'),
		await db.clear('estimate-documents'),
		await db.clear('estimate-document-signatures'),
		await db.clear('local-changes'),
		await db.clear('local-ids'),
		await db.clear('local-ids-map'),
		await db.clear('info'),
		caches.delete(API_IMAGE_CACHE_NAME),
		caches.delete(API_USER_SELF_CACHE_NAME),
	]);
}
