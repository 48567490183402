<template>
	<slot :items="items" :removeItem="removeItem" :addItem="addItem" :addItemAfter="addItemAfter" />
</template>

<script setup>
import { useFieldArray } from 'vee-validate';
import { inject, onMounted, toRef } from 'vue';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
})
const { remove: removeItem, push: pushItem, insert: insertItem, fields: items } = useFieldArray(toRef(props, 'name'));
const getNewFieldArrayItem = inject('getNewFieldArrayItem');
const modelLookup = inject('modelLookup');
const modelValues = inject('modelValues');
const recommendedItems = inject('recommendedItems');
const model = modelLookup(props.name);

const addItem = () => {
	pushItem(getNewFieldArrayItem(props.name));
};
const addItemAfter = i => {
	insertItem(i + 1, getNewFieldArrayItem(props.name));
};

onMounted(() => {
	if (recommendedItems && recommendedItems[model.field.id]) {
		const values = recommendedItems[model.field.id];
		const keys = Object.keys(recommendedItems[model.field.id]);

		const sortedKeys = keys.sort((a, b) => values[b] - values[a]);

		if (items.value.length === sortedKeys.length) {
			let count = 0;
			sortedKeys.forEach((key, i) => {
				if (values[key] === 0 && items.value.length > 1) {
					removeItem(key - count);
					count++;
				}
			});
		}
	}
})

defineExpose({
	items,
	removeItem,
	addItem,
	addItemAfter,
});
</script>
