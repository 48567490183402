import { parseIntProp } from '../_helpers';

export default {
	path: '/config',
	component: () => import('@/views/config/_ConfigApp.vue'),
	meta: { requiresAuth: true, requiresUser: true, requiresAdmin: true, blockOffline: true , appName: 'config',},
	children: [
		{
			path: '',
			redirect: '/config/settings'
		},
		{
			path: 'users',
			component: () => import('@/views/config/UsersPage.vue'),
			meta: { title: 'Users', },
		},
		{
			path: 'users/:id(-?\\d+)',
			component: () => import('@/views/config/UserEditPage.vue'),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'User Edit', },
		},
		{
			path: 'estimate-sections',
			component: () => import('@/views/config/EstimateSectionsPage.vue'),
			meta: { title: 'Estimate Sections', },
		},
		{
			path: 'estimate-fields',
			component: () => import('@/views/config/EstimateFieldsPage.vue'),
			meta: { title: 'Estimate fields', },
		},
		{
			path: 'estimate-field-options',
			component: () => import('@/views/config/EstimateFieldOptionsPage.vue'),
			meta: { title: 'Estimate fields options', },
		},
		{
			path: 'settings',
			component: () => import('@/views/config/SettingsPage.vue'),
			meta: { title: 'Settings', },
		},
		{
			path: 'email-settings',
			component: () => import('@/views/config/EmailSettingsPage.vue'),
			meta: { title: 'Email Settings', },
		},
		{
			path: 'contract-settings',
			component: () => import('@/views/config/ContractSettingsPage.vue'),
			meta: { title: 'Contract Settings', },
		},
		{
			path: 'file-settings',
			component: () => import('@/views/config/FileSettingsPage.vue'),
			meta: { title: 'File Settings', },
		},
		{
			path: 'hover-integration',
			component: () => import('@/views/config/HoverIntegrationPage.vue'),
			meta: { title: 'Hover Settings', },
		},
		{
			path: 'zoho-integration',
			component: () => import('@/views/config/ZohoIntegrationPage.vue'),
			meta: { title: 'Zoho Settings', },
		},
		{
			path: 'api-integration',
			component: () => import('@/views/config/ApiIntegrationPage.vue'),
			meta: { title: 'API Integration', },
		},
		{
			path: 'sections-info',
			component: () => import('@/views/config/SectionsInfo.vue'),
			meta: { title: 'Sections Info', },
		},
		{
			path: 'usage-report',
			component: () => import('@/views/config/UsageReport.vue'),
			meta: { title: 'Usage Report', },
		},
		{
			path: 'payment-options',
			component: () => import('@/views/config/PaymentOptions.vue'),
			meta: { title: 'Payment Options', },
		},
	],
};
