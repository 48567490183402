import FileReference from '@/models/FileReference';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/files/' + id);
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			return new FileReference(await response.json());
		} else {
			return response;
		}
	},
	async upload(file) {
		const formData = new FormData();
		formData.append('formFile', file);
		try {
			const response = await fetchWrap('/api/files', {
				method: 'POST',
				body: formData,
			});
			if (response.ok) {
				const data = await response.json();
				return new FileReference(data);
			} else {
				return response;
			}
		} catch (e) {
			return offlineResponse();
		}
	}
};
