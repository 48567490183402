import { getValueFromDto as getValue, getDateTimeFromDto as getDate } from './_helper';

export default class Lead {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.externalId = getValue(dto, 'externalId', 'string', '');
		this.userId = getValue(dto, 'userId', 'number', null);
		Object.defineProperty(this, 'user', { value: null, writable: true });
		this.additionalSalesmanId = getValue(dto, 'additionalSalesmanId', 'number', null);
		Object.defineProperty(this, 'additionalSalesman', { value: null, writable: true });
		this.userExternalId = getValue(dto, 'userExternalId', 'string', '');
		this.firstName = getValue(dto, 'firstName', 'string', '');
		this.lastName = getValue(dto, 'lastName', 'string', '');
		this.company = getValue(dto, 'company', 'string', '');
		this.phone = getValue(dto, 'phone', 'string', '');
		this.mobilePhone = getValue(dto, 'mobilePhone', 'string', '');
		this.email = getValue(dto, 'email', 'string', '');
		this.street = getValue(dto, 'street', 'string', '');
		this.city = getValue(dto, 'city', 'string', '');
		this.state = getValue(dto, 'state', 'string', '');
		this.zipCode = getValue(dto, 'zipCode', 'string', '');
		this.description = getValue(dto, 'description', 'string', '');
		this.projectType = getValue(dto, 'projectType', 'string', '');
		this.projectTypes = getValue(dto, 'projectTypes', 'object', '');
		this.leadStatus = getValue(dto, 'leadStatus', 'string', '');
		this.customerNumber = getValue(dto, 'customerNumber', 'string', '');
		this.projectFolderLink = getValue(dto, 'projectFolderLink', 'string', '');
		this.projectFolderId = getValue(dto, 'projectFolderId', 'string', '');
		this.contractLink = getValue(dto, 'contractLink', 'string', '');
		this.contractFolderId = getValue(dto, 'contractFolderId', 'string', '');
		this.estimationLink = getValue(dto, 'estimationLink', 'string', '');
		this.estimationFolderId = getValue(dto, 'estimationFolderId', 'string', '');
		this.productionLink = getValue(dto, 'productionLink', 'string', '');
		this.productionFolderId = getValue(dto, 'productionFolderId', 'string', '');
		this.meetingDate = getDate(dto, 'meetingDate', null);
		this.createdTime = getDate(dto, 'createdTime', null);
		this.modifiedTime = getDate(dto, 'modifiedTime', null);
		this.hoverJobId = getValue(dto, 'hoverJobId', 'number', null);
		this.cameraUrl = getValue(dto, 'cameraUrl', 'string', '');
	}

	hasProjectFolders() {
		return !!(this.projectFolderLink || this.contractLink || this.estimationLink || this.productionLink);
	}
}
