<template>
	<div class="error-summary-contain" ref="errorsWidget" v-on="focusListeners" tabindex="0">
		<button class="error-summary-btn" :class="{ pulse: !meta.valid }" type="button" @click="toggleWidget">
			<svg width="30px" height="30px" class="icon stroke-white fill-red" v-if="!meta.valid">
				<use xlink:href="#warn-triangle"></use>
			</svg>
			<svg width="30px" height="24px" class="icon stroke-green" v-else>
				<use xlink:href="#check-2"></use>
			</svg>
		</button>
		<div class="error-summary-dropdown" :class="{ 'expanded': isOpen, 'no-errors': meta.valid }">
			<ul v-if="Object.keys(errors).length > 0">
				<li class="dropdown-header">
					<div>{{ Object.keys(errors).length }} {{ Object.keys(errors).length > 1 ? 'errors' : 'errors' }} in form</div>
					<div @click="toggleWidget" class="dropdown-close"></div>
				</li>
				<li v-for="(error, key) in errors" :key="key">{{ error }}</li>
			</ul>
			<div class="no-errors" v-else>
				<div class="flex-center-y">
					<svg width="18px" height="18px" class="icon stroke-white fill-white">
						<use xlink:href="#check"></use>
					</svg>
					No errors in form
				</div>

				<div @click="toggleWidget" class="dropdown-close"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';
	import { getFocus } from '@/composables/focus';

	export default {
		props: {
			meta: {
				type: Object,
				required: true,
			},
			errors: {
				type: Object,
				required: true,
			},
			validate: {
				type: Function,
				required: true,
			},
		},
		setup(props) {
			const errorsWidget = ref(null);
			const { isOpen, focusListeners } = getFocus(errorsWidget);

			async function toggleWidget() {
				isOpen.value = !isOpen.value;
				if (isOpen.value) {
					if (typeof props.validate === 'function') {
						await props.validate();
					}
				}
			}

			return {
				errorsWidget,
				isOpen,
				focusListeners,
				toggleWidget,
			};
		},
	};
</script>
