import { getDataUrlFromBlob } from '@/helpers';
import { getDb } from '@/idbInit';
import * as localChanges from '@/idbLocalChanges';
import * as localIds from '@/idbLocalIds';
import { Document, DocumentReference } from '@/models/Document';
import { DocumentEmailHistory } from '@/models/DocumentEmailHistory';
import DocumentSignature from '@/models/DocumentSignature';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import { DateTime } from 'luxon';
import { fetchWrap, notFoundResponse, offlineResponse } from '../_helpers';

async function addDocumentToEstimate(document) {
	const idb = await getDb();
	const estimate = await idb.get('estimates', document.estimateId);
	if (estimate) {
		const index = estimate.documents.findIndex((x) => x.id === document.id);
		const docRef = JSON.parse(JSON.stringify(new DocumentReference(document)));
		if (index >= 0) {
			estimate.documents.splice(index, 1, docRef);
		} else {
			estimate.documents.push(docRef);
		}
		await idb.put('estimates', estimate, estimate.id);
	}
}

async function removeDocumentFromEstimate(model) {
	const idb = await getDb();
	const estimate = await idb.get('estimates', model.estimateId);
	if (estimate) {
		const newDocuments = estimate.documents.filter((x) => x.id !== model.id);
		if (estimate.documents.length !== newDocuments.length) {
			estimate.documents = newDocuments;
			await idb.put('estimates', estimate, estimate.id);
		}
	}
}

async function deleteDocumentSignaturesFromIdb(id, keys) {
	const idb = await getDb();
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		await idb.delete('estimate-document-signatures', DocumentSignature.getUrl(id, key));
	}
}

export default {
	/**
	 * Get an document
	 * @param {id} Number Document ID
	 * @returns (async) Returns an Document if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		const idb = await getDb();
		id = await localIds.mapLocalId(idb, 'estimate-documents', id);
		if (id > 0) {
			let response;
			try {
				response = await fetchWrap('/api/documents/' + id);
			} catch {
				return offlineResponse();
			}
			if (response.ok) {
				const data = await response.json();
				return new Document(data);
			} else {
				return response;
			}
		} else {
			const data = await idb.get('estimate-documents', id);
			if (!data) { return offlineResponse(); }
			const model = new Document(data);
			for (const key in model.signatures) {
				if (Object.hasOwnProperty.call(model.signatures, key)) {
					const signature = model.signatures[key];
					if (signature.url) {
						const data = await idb.get('estimate-document-signatures', signature.url);
						if (data) {
							signature.url = await getDataUrlFromBlob(data);
						}
					}
				}
			}
			return model;
		}
	},
	/**
	 * Create a document
	 * @param {model} Document document to create.
	 * @returns (async) Returns the new Document if the request was successful, otherwise a Response.
	 */
	async create(model) {
		const idb = await getDb();
		model.estimateId = await localIds.mapLocalId(idb, 'estimates', model.estimateId);
		model.leadId = await localIds.mapLocalId(idb, 'leads', model.leadId);
		const formData = new FormData();
		formData.append('estimateId', model.estimateId);
		formData.append('name', model.name);
		formData.append('type', model.type);
		for (let i = 0; i < model.workflows.length; i++) {
			formData.append('workflows', model.workflows[i]);
		}
		formData.append('createdTime', model.createdTime ? model.createdTime.toISO() : '');
		for (const key in model.data) {
			if (Object.hasOwnProperty.call(model.data, key)) {
				let value = model.data[key];
				if (value !== null && value !== undefined && value !== '') {
					if (value instanceof DateTime) {
						value = value.toISO();
					} else if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
						value = JSON.stringify(value)
					}
					formData.append('data.' + key, value);
				}
			}
		}
		for (const key in model.signatures) {
			if (Object.hasOwnProperty.call(model.signatures, key)) {
				const signature = model.signatures[key];
				if (signature instanceof DocumentSignature && signature.timestamp && signature.data) {
					formData.append('signatures.' + key + '.timestamp', signature.timestamp);
					formData.append('signatures.' + key + '.data', signature.data);
				}
			}
		}

		let response;
		try {
			response = await fetchWrap('/api/documents/', {
				method: 'POST',
				body: formData,
			});
		} catch {
			if (model.id < 0) {
				const data = await idb.get('estimate-documents', model.id);
				return data ? new Document(data) : offlineResponse();
			}
			// get local id first so it is included in the signature URLs
			const data = {
				id: await localIds.getNewId(idb, 'estimate-documents'),
				workflows: [],
				data: {},
				signatures: {},
			};
			// build data from formData
			for (const entry of formData.entries()) {
				const key = entry[0], value = entry[1];
				// special cases: workflows (array), data (object), signatures (object)
				if (key == 'workflows') {
					data.workflows.push(parseInt(value));
				} else if (key.indexOf('data.') === 0) {
					// 5 = length of 'data.'
					const key2 = key.substring(5);
					data.data[key2] = value;
				} else if (key.indexOf('signatures.') === 0) {
					// 11 = length of 'signatures.'
					const dot2 = key.indexOf('.', 11);
					const key2 = key.substring(11, dot2);
					const key3 = key.substring(dot2 + 1);
					if (!(key2 in data.signatures)) {
						data.signatures[key2] = {};
					}
					data.signatures[key2][key3] = value;
				} else if (key === 'id' || key === 'estimateId' || key === 'leadId' || key === 'createdByUserId' || key === 'type') {
					data[key] = parseInt(value);
					if (isNaN(data[key])) {
						data[key] = null;
					}
				} else {
					data[key] = value;
				}
			}
			// signatures
			for (const key in data.signatures) {
				if (Object.hasOwnProperty.call(data.signatures, key)) {
					const signature = data.signatures[key];
					signature.url = DocumentSignature.getUrl(data.id, key)
					await idb.put('estimate-document-signatures', signature.data, signature.url);
					signature.data = null;
				}
			}
			// add the document
			await idb.put('estimate-documents', data, data.id);
			await localChanges.add(idb, new LocalChange({ storeName: 'estimate-documents', id: data.id, state: LocalChangeState.added }));
			await addDocumentToEstimate(data);
			return new Document(data);
		}
		if (response.ok) {
			const data = await response.json();
			await localIds.addLocalIdMap(idb, 'estimate-documents', model.id, data.id);
			// delete document from idb
			if (model.id < 0) {
				await idb.delete('estimate-documents', model.id);
				await deleteDocumentSignaturesFromIdb(model.id, Object.keys(model.signatures));
			}
			await localChanges.deleteChange(idb, LocalChange.getKey('estimate-documents', model.id));
			await addDocumentToEstimate(data);
			return new Document(data);
		} else {
			return response;
		}
	},
	async getEmailHistory(id) {
		const idb = await getDb();
		id = await localIds.mapLocalId(idb, 'estimate-documents', id);
		let response;
		try {
			response = await fetchWrap(`/api/documents/${id}/emailhistory`);
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return data.map(x => new DocumentEmailHistory(x));
		} else {
			return response;
		}
	},
	/**
	 * Generate PDF
	 * @param {id} Number document ID to email.
	 * @returns (async) Returns the updated DocumentReference if the request was successful, otherwise a Response.
	 */
	async generatePdf(id) {
		const idb = await getDb();
		id = await localIds.mapLocalId(idb, 'estimate-documents', id);
		let response;
		try {
			response = await fetchWrap(`/api/documents/${id}/generatepdf`, { method: 'POST' });
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			await addDocumentToEstimate(data);
			return new DocumentReference(data);
		} else {
			return response;
		}
	},
	/**
	 * Email a document
	 * @param {id} Number document ID to email.
	 * @param {emailAddresses} Array recipient email addresses.
	 * @param {message} string a custom message to include in the email
	 * @returns (async) Returns the new DocumentEmailHistory if the request was successful, otherwise a Response.
	 */
	async email(id, emailAddresses, message) {
		const idb = await getDb();
		id = await localIds.mapLocalId(idb, 'estimate-documents', id);
		let response;
		try {
			response = await fetchWrap('/api/documents/email', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					documentId: id,
					emailAddresses,
					message,
				}),
			});
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return new DocumentEmailHistory(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete an document
	 * @param {id} Number Document ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the document could not be deleted, otherwise a Response.
	 */
	async delete(model) {
		const idb = await getDb();
		model.estimateId = await localIds.mapLocalId(idb, 'estimates', model.estimateId);
		model.leadId = await localIds.mapLocalId(idb, 'leads', model.leadId);
		let response;
		try {
			if (model.id < 0) {
				return notFoundResponse();
			} else {
				response = await fetchWrap('/api/documents/' + model.id, { method: 'DELETE' });
			}
		} catch {
			const idbModel = await idb.get('estimate-documents', model.id);
			if (idbModel) {
				await deleteDocumentSignaturesFromIdb(model.id, Object.keys(idbModel.signatures));
				await idb.delete('estimate-documents', model.id);
			}
			await removeDocumentFromEstimate(model);
			await localChanges.add(idb, new LocalChange({ storeName: 'estimate-documents', id: model.id, state: LocalChangeState.deleted }));
			return true;
		}
		if (response.ok || response.status === 404) {
			const idbModel = await idb.get('estimate-documents', model.id);
			if (idbModel) {
				await deleteDocumentSignaturesFromIdb(model.id, Object.keys(idbModel.signatures));
				await idb.delete('estimate-documents', model.id);
			}
			await removeDocumentFromEstimate(model);
			await localChanges.deleteChange(idb, LocalChange.getKey('estimate-documents', model.id));
			return true;
		} else {
			return response;
		}
	}
};
