import MessageType from './MessageType'

export default class Message {
	constructor() {
		this.message = '';
		this.id = 0;
		this.type = MessageType.info;
		this.autoClose = false;
	}

	static AUTO_CLOSE_DELAY = 5000; // in milliseconds
}
