import { getDb } from '@/idbInit';
import EstimateWorkflow from '@/models/EstimateWorkflow';
import EstimateWorkflows from '@/models/EstimateWorkflows';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	async getAll() {
		const map = {};
		const baseData = EstimateWorkflows.list.map(x => {
			const y = new EstimateWorkflow({ id: x.id });
			map[y.id] = y;
			return y;
		});
		let response;
		try {
			response = await fetchWrap('/api/estimateworkflows');
		} catch {
			const idb = await getDb();
			const data = await idb.getAll('estimate-workflows');
			for (let i = 0; i < data.length; i++) {
				const model = map[data[i].id];
				if (model) {
					model.setDynamicData(data[i]);
				}
			}
			return baseData;
		}
		if (response.ok) {
			const data = await response.json();
			const idb = await getDb();
			await idb.clear('estimate-workflows');
			for (let i = 0; i < data.length; i++) {
				await idb.put('estimate-workflows', data[i], data[i].id);
				const model = map[data[i].id];
				if (model) {
					model.setDynamicData(data[i]);
				}
			}
			return baseData;
		} else {
			return response;
		}
	},
	/**
	 * Update an estimate workflow
	 * @param {model} EstimateWorkflow estimate workflow to update.
	 * @returns (async) Returns the updated EstimateWorkflow if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/estimateworkflows/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = JSON.parse(JSON.stringify(model));
			const idb = await getDb();
			await idb.put('estimate-workflows', data, data.id);
			return new EstimateWorkflow(data);
		} else {
			return response;
		}
	},
};
