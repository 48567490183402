import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights = null;
/**
 * @param {Object} options
 * @param {import('vue').App<Element>} options.app
 * @param {string} options.connectionString
 * @param {import('vue-router').Router} options.router
 * @param {string} options.version
 */
export function initApplicationInsights({ app, connectionString, router, version }) {
	const ai = new ApplicationInsights({
		config: {
			connectionString: connectionString,
			maxAjaxCallsPerView: -1,
			disableDataLossAnalysis: false,
			enableAutoRouteTracking: false,
			disableFetchTracking: false,
			enableCorsCorrelation: true,
			enableRequestHeaderTracking: true,
			enableResponseHeaderTracking: true,
			correlationHeaderExcludedDomains: ['*.queue.core.windows.net', 'apis.google.com', 'www.googleapis.com'],
		},
		version: version,
	});
	appInsights = ai;
	ai.loadAppInsights();

	const errorHandler = app.config.errorHandler;
	app.config.errorHandler = (error, instance, info) => {
		ai.trackException({ exception: error });
		if (typeof errorHandler === 'function') {
			errorHandler.call(app, error, instance, info);
		}
	};

	router.isReady().then(() => {
		router.onError(error => {
			ai.trackException({ exception: error });
		});
		router.afterEach((to, from, failure) => {
			if (failure) { return; }
			ai.trackPageView({
				name: to.meta.title ?? '',
				uri: location.origin + to.fullPath,
			});
		});
	})
}

export function getAppInsights() {
	return appInsights
}
