<template>
	<textarea v-if="multiline" :id="htmlFieldName" v-model="value" v-bind="$attrs" :disabled="disabled"></textarea>
	<input v-else type="text" :id="htmlFieldName" v-model="value" v-bind="$attrs" :disabled="disabled" />
</template>

<script setup>
import { computed, inject, toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	multiline: {
		type: Boolean,
		required: false,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const { value } = useField(toRef(props, 'name'));
</script>

<script>
export default { inheritAttrs: false }
</script>
