import EstimateFieldOption from '@/models/EstimateFieldOption';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/estimatefields/option/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return new EstimateFieldOption(data);
		} else {
			return response;
		}
	},
};
