<template>
	<button :class="altBtnClass" class="btn" :type="type" :disabled="loading">
		<span v-if="!loading">
			<slot>Submit</slot>
		</span>
		<span class="is-loading dots" v-else>
			<span class="dot-1"></span>
			<span class="dot-2"></span>
			<span class="dot-3"></span>
		</span>
	</button>
</template>

<script>
export default {
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
		altBtnClass: {
			type: String,
			required: false,
			default: 'btn-outline-green',
		},
		type: {
			type: String,
			required: false,
			default: 'submit',
		},
	},
};
</script>
