import api from '@/api/index';
import { cacheFiles } from '@/api/sw/cacheFiles';
import { getRandomColor, setLuxonTimeZone, setThemeColor } from '@/helpers';
import Message from '@/models/Message';
import MessageType from '@/models/MessageType';
import Settings from '@/models/Settings';
import { DateTime } from 'luxon';
import { createStore } from 'vuex';
import auth from './modules/auth';
import estimator from './modules/estimator';

let env = 'production';
if (window.location.hostname === 'localhost' || /\.ngrok\.io$/.test(window.location.hostname)) {
	env = 'development'
} else if (/^ihshomebuilder-testing\.azurewebsites\.net$/.test(window.location.hostname)) {
	env = 'testing'
} else if (/^ihshomebuilder-staging\.azurewebsites\.net$/.test(window.location.hostname)) {
	env = 'staging'
}

const store = createStore({
	strict: env === 'development' && process.env.NODE_ENV !== 'production',
	modules: {
		auth,
		estimator,
	},
	state: {
		environment: env,
		settings: null,
		isOnline: true,
		installPrompt: null,
		showInstallBanner: false,
		showInstallButton: false,
		serviceWorkerUpdate: false,
		messages: [],
		unsyncedChanges: 0,
		syncing: false,
		lastRefresh: null,
	},
	mutations: {
		setSettings(state, settings) {
			if (settings !== null && settings instanceof Settings) {
				state.settings = settings;
			} else {
				state.settings = null;
			}
			// set time zone
			setLuxonTimeZone(state.settings ? state.settings.timeZone : null);
			// set company theme color
			let themeColor = null;
			if (state.environment === 'development' && process.env.NODE_ENV !== 'production') {
				themeColor = getRandomColor();
			} else {
				themeColor = (state.settings ? state.settings.themeColor : null) || '#2ab0da';
			}
			setThemeColor(themeColor);
		},
		setOnlineStatus(state, status) {
			if (status) {
				state.isOnline = true;
			} else {
				state.isOnline = false;
			}
		},
		setServiceWorkerUpdate(state, status) {
			if (status) {
				state.serviceWorkerUpdate = true;
			} else {
				state.serviceWorkerUpdate = false;
			}
		},
		setPwaInstallButton(state, status) {
			if (status) {
				state.showInstallButton = true;
			} else {
				state.showInstallButton = false;
			}
		},
		setCustomPwaBanner(state, status) {
			if (status && localStorage.getItem('pwaUserChoice') != 'no') {
				state.showInstallBanner = true;
			} else {
				state.showInstallBanner = false;
			}
		},
		setPwaPrompt(state, event) {
			if (event != null) {
				state.installPrompt = event;
			}
		},
		addMessage(state, message) {
			if (!(message instanceof Message)) { return; }
			if (state.messages.findIndex(x => x.id === message.id) >= 0) { return; }
			state.messages.push(message);
		},
		removeMessage(state, messageId) {
			const index = state.messages.findIndex(x => x.id === messageId);
			if (index >= 0) {
				state.messages.splice(index, 1);
			}
		},
		setUnsyncedChanges(state, unsyncedChanges) {
			if (typeof unsyncedChanges === 'number') {
				state.unsyncedChanges = unsyncedChanges;
			}
		},
		setSyncing(state, syncing) {
			if (typeof syncing === 'boolean') {
				state.syncing = syncing;
			}
		},
		setLastRefresh(state, timestamp) {
			if (timestamp instanceof DateTime) {
				state.lastRefresh = timestamp;
			} else {
				state.lastRefresh = null;
			}
		},
	},
	actions: {
		async clear({ dispatch, commit }) {
			commit('setSettings', null);
			await dispatch('auth/clear');
			await dispatch('estimator/clear');
		},
		async refresh({ dispatch }) {
			await dispatch('auth/refresh');
			await dispatch('refreshAfterAuth');
		},
		async refreshAfterAuth({ dispatch, commit }) {
			await Promise.all([
				dispatch('refreshSettings'),
				dispatch('estimator/refresh'),
			]);
			commit('setLastRefresh', DateTime.now());
		},
		async refreshSettings({ getters, commit }) {
			const settings = getters['auth/isUser'] ? await api.settings.getSettings() : null;

			if (settings != null && process.env.NODE_ENV === 'production') {
				const files = [];
				if (settings.contractWarrantyImage) { files.push(settings.contractWarrantyImage); }
				if (settings.logoDarkImage) { files.push(settings.logoDarkImage); }
				if (settings.logoImage) { files.push(settings.logoImage); }
				if (settings.contractLogos != null) {
					for (let i = 0; i < settings.contractLogos.length; i++) {
						files.push(settings.contractLogos[i]);
					}
				}
				cacheFiles(files);
			}

			return commit('setSettings', settings);
		},
		addMessage({ commit, state }, { message, type = MessageType.info, autoClose = true }) {
			if (!(typeof message === 'string')) { return; }
			message = message.trim();
			if (!message) { return; }

			const currentId = state.messages.length > 0 ? state.messages.reduce((id, x) => Math.max(id, x.id), 0) : 0;
			const m = new Message();
			m.id = currentId + 1;
			m.type = MessageType.isValid(type) ? type : MessageType.info;
			m.message = message;
			m.autoClose = !!autoClose;

			commit('addMessage', m);
			if (autoClose) {
				setTimeout(() => {
					commit('removeMessage', m.id);
					// 1s delay for animate out:
				}, Message.AUTO_CLOSE_DELAY + 1000);
			}
		},
	},
});

export default store;
