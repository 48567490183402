<template>
	<div class="thumbnail-group">
		<div
			class="package-thumbnail has-img has-img-multiple"
			:class="{ 'constrain-img': constrain, 'highlight': dragging }"
		>
			<img v-if="value.url" :src="value.url" />
			<img v-else src="@/img/unknown-image.png" />
		</div>
		<div class="mt-half">
			<label class="label-error mt-third mb-1" v-show="error">{{ error }}</label>
			<button class="btn-link btn-link-delete" type="button" @click="removeItem(index)">Remove</button>
		</div>
	</div>
</template>

<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	removeItem: {
		type: Function,
		required: true,
	},
	constrain: {
		type: Boolean,
		required: false,
		default: false,
	},
	dragging: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const { value, errorMessage: error } = useField(toRef(props, 'name'), undefined, { validateOnMount: true });
</script>
