import { DateTime } from "luxon";

export function parseIntProp(rawValue) {
	const value = parseInt(rawValue);
	return isNaN(value) ? undefined : value;
}

export function parseBoolProp(rawValue) {
	if (rawValue === 'true') {
		return true;
	} else if (rawValue === 'false') {
		return false;
	}
}

export function parseDateTimeProp(rawValue) {
	let value = rawValue ?? '';
	if (!/^\d\d\d\d-\d\d-\d\d$/.test(value)) { value = ''; }
	value = DateTime.fromISO(value);
	return value && value.isValid ? value : undefined;
}
