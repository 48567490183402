import { ref, watch, reactive } from 'vue';

export function getFocus(focusRef) {
	const isOpen = ref(false);

	const focusListeners = reactive({
		focusout: null,
	});

	watch(isOpen, (newValue, oldValue) => {
		if (newValue && !oldValue) {
			window.addEventListener('keydown', escapeListener, true);
			focusListeners.focusout = focusOutListener;
		} else if (!newValue && oldValue) {
			window.removeEventListener('keydown', escapeListener);
			focusListeners.focusout = null;
		}
	});

	function escapeListener(event) {
		if (event.key === 'Esc' || event.key === 'Escape') {
			isOpen.value = false;
		}
	}

	function focusOutListener(event) {
		if (!focusRef.value || !focusRef.value.contains(event.relatedTarget)) {
			isOpen.value = false;
		}
	}

	return {
		isOpen,
		focusListeners,
	};
}
