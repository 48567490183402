import EstimateWorkflows from './EstimateWorkflows';
import { getValueFromDto as getValue } from './_helper';

class EstimateWorkflow {
	constructor(dto) {
		this.setDynamicData(dto);
	}

	setDynamicData(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.enabled = getValue(dto, 'enabled', 'boolean', false);
		this.alias = getValue(dto, 'alias', 'string', '');
		Object.defineProperty(this, 'sections', { value: [], writable: true });
		Object.defineProperty(this, 'loading', { value: false, writable: true });
	}

	get key() {
		return EstimateWorkflows.getKey(this.id);
	}

	get name() {
		return EstimateWorkflows.getName(this.id);
	}

	get originalName() {
		return EstimateWorkflows.getOriginalName(this.id);
	}

	get order() {
		return EstimateWorkflows.getOrder(this.id);
	}
}

export default EstimateWorkflow;
