/* eslint-disable no-console */

import { register } from 'register-service-worker';
import $store from './store/index';

if (process.env.NODE_ENV === 'production') {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.debug(
				'App is being served from cache by a service worker.\n' +
				'For more details, visit https://goo.gl/AFskqB'
			);

			(async () => {
				const registration = await navigator.serviceWorker.ready;
				if ('periodicSync' in registration) {
					try {
						await registration.periodicSync.register('sync-download', {
							// An interval of 1 hours. Acts as a suggestion to the browser.
							// Might end up only being once per day
							minInterval: 1 * 60 * 60 * 1000,
						});
					} catch (error) {
						console.debug('Error registering periodic sync', error);
					}
				}
			})();
		},
		registered() {
			console.debug('Service worker has been registered.');
		},
		cached() {
			console.debug('Content has been cached for offline use.')
		},
		updatefound() {
			console.debug('New content is downloading.')
		},
		updated() {
			console.debug('New content is available; please refresh.');
			$store.commit('setServiceWorkerUpdate', true);
		},
		offline() {
			console.debug('No internet connection found. App is running in offline mode.')
		},
		error(error) {
			console.error('Error during service worker registration:', error)
		}
	})
}
