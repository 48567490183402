import { getValueFromDto as getValue } from './_helper';

export default class MicrosoftOneDriveInfo {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'string', '');
		this.name = getValue(dto, 'name', 'string', '');
		this.type = getValue(dto, 'type', 'string', '');
		this.url = getValue(dto, 'url', 'string', '');
	}
}
