import { INT_MAX_VALUE } from '@/helpers';
import { useStore } from 'vuex';

export default class EstimateWorkflows {
	static general = 0;
	static roofing = 1;
	static siding = 2;
	static windows = 3;
	static gutters = 4;
	static trim = 7;
	static repairs = 5;
	static insulation = 6;

	static map = {
		[this.general]: { id: this.general, order: 0, key: 'general', name: 'General' },
		[this.roofing]: { id: this.roofing, order: 1, key: 'roofing', name: 'Roofing' },
		[this.siding]: { id: this.siding, order: 2, key: 'siding', name: 'Siding' },
		[this.windows]: { id: this.windows, order: 3, key: 'windows', name: 'Windows & Doors' },
		[this.gutters]: { id: this.gutters, order: 4, key: 'gutters', name: 'Gutters' },
		[this.trim]: { id: this.trim, order: 5, key: 'trim', name: 'Trim' },
		[this.insulation]: { id: this.insulation, order: 6, key: 'insulation', name: 'Insulation' },
		[this.repairs]: { id: this.repairs, order: 7, key: 'repairs', name: 'Repairs' },
	};

	static list = Array.from(Object.values(this.map)).sort((a, b) => a.order - b.order);

	static getKey(id) {
		if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].key;
		} else {
			return '';
		}
	}

	static getName(id) {
		const $store = useStore();

		if ($store) {
			const workflow = $store.getters['estimator/getWorkflowById'](id);

			if (Object.hasOwnProperty.call(this.map, id)) {
				return workflow.alias ? workflow.alias :  this.map[id].name;
			}
		} else if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].name;
		} else {
			return '';
		}
	}

	static getOriginalName(id) {
		const $store = useStore();

		const workflow = $store.getters['estimator/getWorkflowById'](id);

		if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].name;
		} else {
			return '';
		}
	}

	static getOrder(id) {
		if (Object.hasOwnProperty.call(this.map, id)) {
			return this.map[id].order;
		} else {
			return INT_MAX_VALUE;
		}
	}
}
