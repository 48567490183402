export default {
	getAll() {
		return [
			{ id: 'America/New_York', name: 'Eastern Time (EST/EDT)' },
			{ id: 'America/Chicago', name: 'Central Time (CST/CDT)' },
			{ id: 'America/Denver', name: 'Mountain Time (MST/MDT)' },
			{ id: 'America/Phoenix', name: 'Mountain Time (MST)' },
			{ id: 'America/Los_Angeles', name: 'Pacific Time (PST/PDT)' },
		];
	},
};
