import LocalChangeState from './LocalChangeState';
import { getValueFromDto as getValue } from './_helper';

class LocalChange {
	constructor(dto) {
		this.storeName = getValue(dto, 'storeName', 'string', '');
		this.id = getValue(dto, 'id', 'number', 0);
		this.state = getValue(dto, 'state', 'number', LocalChangeState.unchanged);
		this.error = getValue(dto, 'error', 'number', null);
		this.data = getValue(dto, 'data', 'object', {});
		if (this.data === null) { this.data = {}; }
	}

	get key() {
		return LocalChange.getKey(this.storeName, this.id);
	}

	static getKey(storeName, id) {
		return `${storeName}:${id}`;
	}

}

// Object.defineProperty(LocalChange.prototype, 'key', { enumerable: true });

export default LocalChange;
