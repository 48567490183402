import { fetchWrap } from "../_helpers";

export async function cacheFiles(files) {
	while (files.length > 0) {
		const file = files.shift();
		try {
			await fetchWrap(file.url);
		} catch {
			break;
		}
	}
}
