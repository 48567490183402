import { getValueFromDto as getValue } from './_helper';

class LocalIdMap {
	constructor(dto) {
		this.storeName = getValue(dto, 'storeName', 'string', '');
		this.oldId = getValue(dto, 'oldId', 'number', 0);
		this.newId = getValue(dto, 'newId', 'number', 0);
	}

	get key() {
		return LocalIdMap.getKey(this.storeName, this.oldId);
	}

	static getKey(storeName, oldId) {
		return `${storeName}:${oldId}`;
	}
}

// Object.defineProperty(LocalIdMap.prototype, 'key', { enumerable: true });

export default LocalIdMap;
