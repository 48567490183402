import api from '@/api/index';
import { copyValues, createCallbacks } from '@/helpers';
import MessageType from '@/models/MessageType';
import Settings from '@/models/Settings';
import { useField, useFieldArray, useForm } from 'vee-validate';
import { computed, inject, onMounted, reactive } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { object as yupObject } from 'yup';
import { onBeforeUnload } from './beforeUnload';
import { compare as jsonPatchCompare } from 'fast-json-patch';

export function createListeners(valueRef, errorMessageRef, handleChange, valueTransform) {
	return computed(() => {
		// lazy if the field is valid or have not been validated yet
		const shouldValidate = errorMessageRef.value ? true : false;
		if (typeof valueTransform !== 'function') {
			valueTransform = x => x;
		}
		return {
			blur: e => valueRef.value ? handleChange(valueTransform(e.target.value.trim())) : undefined,
			change: e => handleChange(valueTransform(e.target.value.trim())),
			input: e => handleChange(valueTransform(e.target.value.trim()), shouldValidate),
		};
	});
}

export function useValidation(modelInfo, confirmOnLeave) {
	const confirmDialog = confirmOnLeave ? inject('confirmDialog') : null;
	const $store = useStore();

	const keys = Object.keys(modelInfo);
	const initialValues = {};
	const validationSchema = {};
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		const element = modelInfo[key];
		if (element.schema) {
			initialValues[key] = element.value;
			validationSchema[key] = element.schema;
		}
	}
	const { values, setErrors, setFieldValue: setModelValue, validate, resetForm, handleSubmit, meta, errors } = useForm({ validationSchema: yupObject(validationSchema), initialValues });
	let model = {};
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		const element = modelInfo[key];
		if (element.schema) {
			const customListeners = element.customListeners;
			if (element.isArray) {
				const { remove: removeArrayItem, push: pushArrayItem, insert: insertArrayItem, fields: arrayItems } = useFieldArray(key);
				model[key] = {
					items: arrayItems,
					addItem: pushArrayItem,
					addItemAfter: insertArrayItem,
					removeItem: removeArrayItem,
				};
			} else {
				const { value, errorMessage, handleChange } = useField(key, undefined, { validateOnValueUpdate: !customListeners });
				model[key] = {
					value,
					error: errorMessage,
					listeners: customListeners ? createListeners(value, errorMessage, handleChange) : null,
				};
			}
		} else {
			model[key] = element.value;
		}
	}
	model = reactive(model);

	async function handleNonOkResponse(response) {
		if (response.status === 400) {
			const serverErrors = await api.helpers.processValidationErrors(response, keys);
			setErrors(serverErrors);
		} else {
			await api.helpers.handleHttpError(response);
		}
	}

	onBeforeRouteLeave(async (to, from) => {
		if (meta.value.dirty && to.path !== from.path && confirmDialog && confirmDialog.value) {
			const navigationConfirmed = await confirmDialog.value.openDialog('Do you really want to leave? You have unsaved changes!', { closeOnConfirm: true });
			// return false to cancel the navigation and stay on the same page
			if (!navigationConfirmed) return false;
		}
	});

	if (confirmOnLeave) {
		onBeforeUnload(() => meta.value.dirty);
	}

	return {
		$store,
		setErrors,
		resetForm,
		validate,
		handleSubmit,
		meta,
		errors,
		model,
		modelValues: values,
		setModelValue,
		initialValues,
		handleNonOkResponse
	};
}

export function useSettingsValidation(modelInfo, confirmDialog, saveLoading) {
	const { resetForm, handleSubmit, model, handleNonOkResponse, $store, setErrors, setModelValue, modelValues } = useValidation(modelInfo, confirmDialog);

	const { addCallback: onBeforeSaveData, callCallbacks: callOnBeforeSaveData } = createCallbacks();
	const { addCallback: onBeforeShowData, callCallbacks: callOnBeforeShowData } = createCallbacks();

	function settingsToValues(settings) {
		const values = {};
		for (const key in modelInfo) {
			if (Object.hasOwnProperty.call(modelInfo, key)) {
				if (Object.hasOwnProperty.call(settings, key)) {
					values[key] = settings[key];
				} else {
					values[key] = modelInfo[key].value;
				}
			}
		}
		callOnBeforeShowData(values);
		return values;
	}

	const save = handleSubmit(async (values, actions) => {
		saveLoading.value = true;
		// vee-validate sometimes removes fields (arrays) when all items have been removed, so adding them back in.
		const defaultSettings = new Settings();
		for (const key of Object.keys(modelInfo)) {
			if (!(key in values)) {
				values[key] = defaultSettings[key];
			}
		}
		const settings = copyValues(new Settings($store.state.settings), values);
		callOnBeforeSaveData(settings);
		const currentData = JSON.parse(JSON.stringify($store.state.settings));
		const newData = JSON.parse(JSON.stringify(settings));
		const patch = jsonPatchCompare(currentData, newData);
		if (patch.length > 0) {
			const response = await api.settings.update(patch);
			if (response instanceof Settings) {
				values = settingsToValues(new Settings(response));
				actions.resetForm({ values });
				$store.dispatch('addMessage', { message: 'Saved', type: MessageType.success, autoClose: true });
				$store.commit('setSettings', response);
			} else {
				await handleNonOkResponse(response);
			}
		} else {
			$store.dispatch('addMessage', { message: 'No changes to save', type: MessageType.success, autoClose: true });
		}
		saveLoading.value = false;
	});

	function fetchData() {
		const values = settingsToValues(new Settings($store.state.settings));
		resetForm({ values });
	}
	onMounted(fetchData);

	return {
		model,
		modelValues,
		save,
		setErrors,
		onBeforeSaveData,
		onBeforeShowData,
		setModelValue
	};
}

/*
export function useAppSettingsValidation(modelInfo, confirmDialog, saveLoading) {
	const savedData = ref(null);
	const { resetForm, handleSubmit, model, handleNonOkResponse, $store, setErrors } = useValidation(modelInfo, confirmDialog);

	const save = handleSubmit(async (values, actions) => {
		saveLoading.value = true;
		const settings = copyValues(new AppSettings(savedData.value), values);
		const response = await api.appSettings.update(settings, savedData.value);
		if (response instanceof AppSettings) {
			savedData.value = response;
			actions.resetForm({ values });
			$store.dispatch('addMessage', { message: 'Saved', type: MessageType.success, autoClose: true });
		} else {
			await handleNonOkResponse(response);
		}
		saveLoading.value = false;
	});

	async function fetchData() {
		const response = await api.appSettings.getAppSettings();
		if (!(response instanceof AppSettings)) {
			await handleNonOkResponse(response);
			return;
		}
		savedData.value = response;
		const values = {};
		for (const key in modelInfo) {
			if (Object.hasOwnProperty.call(modelInfo, key) && Object.hasOwnProperty.call(savedData.value, key)) {
				values[key] = savedData.value[key];
			}
		}
		resetForm({ values });
	}
	onMounted(fetchData);

	return {
		model,
		save,
		setErrors,
	};
}
*/
