<template>
	<div>
		<label>Passwords must contain:</label>
		<ul class="reset-list">
			<li class="check-radio-wrap" v-if="requirements.requireUppercase">
				<input class="ui-check-pswd" type="checkbox" disabled :checked="hasUppercase" />
				<span>At least 1 uppercase letter</span>
			</li>
			<li class="check-radio-wrap" v-if="requirements.requireLowercase">
				<input class="ui-check-pswd" type="checkbox" disabled :checked="hasLowercase" />
				<span>At least 1 lowercase letter</span>
			</li>
			<li class="check-radio-wrap" v-if="requirements.requireDigit">
				<input class="ui-check-pswd" type="checkbox" disabled :checked="hasDigit" />
				<span>At least 1 number</span>
			</li>
			<li class="check-radio-wrap" v-if="requirements.requireNonAlphanumeric">
				<input class="ui-check-pswd" type="checkbox" disabled :checked="hasNonAlphanumeric" />
				<span>At least 1 symbol</span>
			</li>
			<li class="check-radio-wrap" v-if="requirements.minLength > 1">
				<input class="ui-check-pswd" type="checkbox" disabled :checked="meetsMinLength" />
				<span>Minimum {{ requirements.minLength }} characters</span>
			</li>
			<li class="check-radio-wrap" v-if="requirements.maxLength < INT_MAX_VALUE">
				<input class="ui-check-pswd" type="checkbox" disabled :checked="meetsMaxLength" />
				<span>Maximum {{ requirements.maxLength }} characters</span>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { INT_MAX_VALUE, passwordOptions as requirements, validation } from '@/helpers';
import { computed } from 'vue';

const props = defineProps({
	modelValue: {
		type: String,
		required: true,
	},
});

const hasUppercase = computed(() => validation.password.requireUppercase(props.modelValue));
const hasLowercase = computed(() => validation.password.requireLowercase(props.modelValue));
const hasDigit = computed(() => validation.password.requireDigit(props.modelValue));
const hasNonAlphanumeric = computed(() => validation.password.requireNonAlphanumeric(props.modelValue));
const meetsMinLength = computed(() => validation.password.minLength(props.modelValue));
const meetsMaxLength = computed(() => validation.password.maxLength(props.modelValue));
</script>
