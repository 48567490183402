import { getValueFromDto as getValue } from './_helper';

export default class EstimateFieldVariantPrice {
	constructor(dto) {
		this.materialCost = getValue(dto, 'materialCost', 'number', 0);
		this.laborCost = getValue(dto, 'laborCost', 'number', 0);
		this.materialPrice = getValue(dto, 'materialPrice', 'number', 0);
		this.laborPrice = getValue(dto, 'laborPrice', 'number', 0);
		this.min = getValue(dto, 'min', 'number', 0);
		this.max = getValue(dto, 'max', 'number', 0);
		this.minHeight = getValue(dto, 'minHeight', 'number', 0);
		this.maxHeight = getValue(dto, 'maxHeight', 'number', 0);
	}
}
