import { Extension } from '@tiptap/core';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';

class LinterPlugin {
	constructor(doc) {
		this.results = [];
		this.doc = doc;
	}
	record(message, from, to, cssClass, fix) {
		this.results.push({
			message,
			from,
			to,
			cssClass,
			fix,
		});
	}
	scan() {
		return this;
	}
	getResults() {
		return this.results;
	}
}

class Keywords extends LinterPlugin {
	constructor() {
		super(...arguments);
		this.patternIf = /\{\{if\[(?<fieldId>\d+\.\d+(?:\+\d+\.\d+)*)\]\}\}/gi;
		this.patternValue = /\{\{value\[(?<fieldId>\d+\.\d+)\]\}\}/gi;
		this.patternQuantity = /\{\{quantity\[(?<fieldId>\d+\.\d+)\]\}\}/gi;
		this.patternEach = /\{\{each\[(?<fieldId>\d+\.\d+)\]\}\}/gi;
	}
	scan() {
		this.doc.descendants((node, position) => {
			if (!node.isText) {
				return;
			}
			let matches = null;
			while ((matches = this.patternIf.exec(node.text)) !== null) {
				this.record(`On contracts, this line's checkbox will be unchecked if this field has quantity 0.`, position + matches.index, position + matches.index + matches[0].length, 'keyword keywordIf');
			}
			while ((matches = this.patternValue.exec(node.text)) !== null) {
				this.record(`On contracts, this keyword will be replaced with the value of this field.`, position + matches.index, position + matches.index + matches[0].length, 'keyword keywordValue');
			}
			while ((matches = this.patternQuantity.exec(node.text)) !== null) {
				this.record(`On contracts, this keyword will be replaced with the quantity of this field.`, position + matches.index, position + matches.index + matches[0].length, 'keyword keywordQuantity');
			}
			while ((matches = this.patternEach.exec(node.text)) !== null) {
				this.record(`On contracts, this keyword will duplicate the line item as many times as there are items of this field.`, position + matches.index, position + matches.index + matches[0].length, 'keyword keywordEach');
			}
		});
		return this;
	}
}

function runAllLinterPlugins(doc, plugins) {
	const decorations = [];
	const results = plugins.map(RegisteredLinterPlugin => {
		return new RegisteredLinterPlugin(doc).scan().getResults();
	}).flat();
	results.forEach(issue => {
		decorations.push(Decoration.inline(issue.from, issue.to, {
			class: issue.cssClass || 'problem',
			title: issue.message,
		})
		);
	});
	return DecorationSet.create(doc, decorations);
}

const Linter = Extension.create({
	name: 'linter',
	addOptions() {
		return {
			plugins: [],
		};
	},
	addProseMirrorPlugins() {
		const { plugins } = this.options;
		return [
			new Plugin({
				key: new PluginKey('linter'),
				state: {
					init(_, { doc }) {
						return runAllLinterPlugins(doc, plugins);
					},
					apply(transaction, oldState) {
						return transaction.docChanged
							? runAllLinterPlugins(transaction.doc, plugins)
							: oldState;
					},
				},
				props: {
					decorations(state) {
						return this.getState(state);
					},
					handleClick(view, _, event) {
						const target = event.target;
						if (/lint-icon/.test(target.className) && target.issue) {
							const { from, to } = target.issue;
							view.dispatch(view.state.tr
								.setSelection(TextSelection.create(view.state.doc, from, to))
								.scrollIntoView());
							return true;
						}
						return false;
					},
					handleDoubleClick(view, _, event) {
						const target = event.target;
						if (/lint-icon/.test(event.target.className) && target.issue) {
							const prob = target.issue;
							if (prob.fix) {
								prob.fix(view, prob);
								view.focus();
								return true;
							}
						}
						return false;
					},
				},
			}),
		];
	},
});

export default Linter;

export { Keywords };
