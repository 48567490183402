<template>
	<input type="number" :id="htmlFieldName" min="0" step="0.01" :value="value" @input="updateModelValue($event.target.value)" @blur="updateIfEmpty($event.target.value)" :disabled="disabled" />
</template>

<script setup>
import { computed, inject, toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const { value } = useField(toRef(props, 'name'));

function updateIfEmpty(v) {
	if (v.trim() === '') {
		// change twice to ensure the input value is updated
		value.value = 1;
		value.value = 0;
	}
}
function updateModelValue(v) {
	if (!v) {
		return;
	}
	let numberValue = parseFloat(v);
	if (isNaN(numberValue)) {
		numberValue = 0;
	}
	value.value = numberValue;
}
</script>
