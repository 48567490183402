import { onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

export function safeComputed(getter, unwatchOnLeave) {
	const value = ref(getter());
	let unwatch = () => { };
	onMounted(() => {
		unwatch = watch(getter, (newValue) => {
			if (newValue !== null && newValue !== undefined) {
				value.value = newValue;
			}
		});
	});
	if (unwatchOnLeave) {
		onBeforeRouteLeave(() => {
			unwatch();
		});
	}
	return value;
}
