import { getValueFromDto as getValue } from "./_helper";

export default class PaginatedList {
	constructor(dto) {
		this.data = [];
		this.offset = getValue(dto, 'offset', 'number', 0);
		this.limit = getValue(dto, 'limit', 'number', 0);
		this.totalCount = getValue(dto, 'totalCount', 'number', 0);
	}

	get hasNextPage() {
		return this.totalCount > this.offset + this.limit;
	}

	get hasPreviousPage() {
		return this.offset > 0;
	}
}
