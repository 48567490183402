import companyId from '@/api/modules/companyId';
import { mapLocalId } from '@/idbLocalIds';
import $store from '@/store/index';
import { DateTime } from 'luxon';
import { createRouter, createWebHistory } from 'vue-router';
import account from './modules/account';
import admin from './modules/admin';
import config from './modules/config';
import estimator from './modules/estimator';

function getHomePagePath() {
	let path = '/404';
	if (!$store.getters['auth/isLoggedIn']) {
		path = '/account/login';
	} else if ($store.getters['auth/isUser']) {
		path = '/estimator';
	} else if ($store.getters['auth/isSuperAdmin']) {
		path = '/admin';
	} else {
		path = '/account/companies';
	}
	return path;
}

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		{
			path: '/',
			meta: { title: 'Home', },
			redirect: (to) => ({ path: getHomePagePath(), query: to.query }),
		},
		{
			path: '/access-denied',
			component: () => import('@/views/AccessDeniedPage.vue'),
			meta: { title: 'Access Denied', },
		},
		{
			path: '/about',
			component: () => import('@/views/AboutPage.vue'),
			meta: { title: 'About', },
		},
		{
			path: '/policies',
			component: () => import('@/views/PoliciesPage.vue'),
			meta: { title: 'Policies', },
		},
		account,
		admin,
		config,
		estimator,
		{
			path: '/offline',
			component: () => import('@/views/OfflinePage.vue'),
			props: route => ({ referrer: route.query.referrer, }),
			meta: { title: 'Offline', },
		},
		{
			path: '/:pathMatch(.*)*',
			component: () => import('@/views/NotFoundPage.vue'),
			meta: { title: 'Page Not Found', is404: true, },
		},
	]
});

async function addMessageFromQuery(to) {
	const message = to.query.message;
	const messageType = to.query.messageType;
	const messageAutoHide = to.query.messageAutoHide;
	if (message) {
		const query = Object.assign({}, to.query);
		delete query.message;
		delete query.messageType;
		delete query.messageAutoHide
		await $store.dispatch('addMessage', { message, type: messageType, autoClose: messageAutoHide });
		// redirect to the same path, without the message
		return {
			path: to.path,
			query: query,
			hash: to.hash,
		};
	}
}

router.beforeEach(async (to) => {
	const messageRedirect = await addMessageFromQuery(to);
	if (messageRedirect) { return messageRedirect; }

	if (to.meta.blockOffline && !$store.state.isOnline) {
		return {
			path: '/offline',
			query: { referrer: to.fullPath },
		}
	}

	const isLoggedIn = $store.getters['auth/isLoggedIn'];
	// if this route requires auth and the user is not logged in
	if (to.meta.requiresAuth && !isLoggedIn) {
		// redirect to login page, save the current url as referrer
		return {
			path: '/account/login',
			query: { referrer: to.fullPath },
		}
	} else if (to.meta.requiresAnonymous && isLoggedIn) {
		// redirect to home page
		return { path: '/' }
	}

	// permissions
	const isSuperAdmin = $store.getters['auth/isSuperAdmin'];
	const isAdmin = $store.getters['auth/isAdmin'];
	const isUser = $store.getters['auth/isUser'];
	if (to.meta.requiresSuperAdmin && !isSuperAdmin) {
		return { path: '/access-denied' };
	} else if (to.meta.requiresUser && !isUser) {
		if (isSuperAdmin) {
			return { path: '/admin' };
		} else {
			return { path: '/account/companies' };
		}
	} else if (to.meta.requiresAdmin && !isAdmin) {
		if (isSuperAdmin) {
			return { path: '/admin' };
		} else {
			return { path: '/access-denied' };
		}
	}

	// leave company automatically
	if (to.meta.requiresSuperAdmin && isSuperAdmin && $store.getters['auth/isUser']) {
		companyId.set(null);
		await companyId.writeToIdbAndBroadcast();
		$store.commit('setUnsyncedChanges', 0);
		$store.commit('setSyncing', false);
		$store.commit('setLastRefresh', null);
		await $store.dispatch('refresh');
	}

	if (to.meta.checkLocalId && to.params.id < 0) {
		const newId = await mapLocalId(to.meta.checkLocalId, to.params.id);
		if (newId > 0) {
			return { path: to.path.replace('/' + to.params.id, '/' + newId) };
		}
	}

	// Mimic service worker updating on navigation for SPA
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then(worker => worker.update());
	}
});

router.beforeResolve(async () => {
	if ($store.state.lastRefresh && (DateTime.now() - $store.state.lastRefresh) > (3 * 60 * 60 * 1000)) {
		await $store.dispatch('refresh');
	}
});

router.afterEach((to) => {
	let appName = 'IHS Home Builder';
	if ($store.state.environment === 'development') {
		appName += ' - Development'
	} else if ($store.state.environment === 'testing') {
		appName += ' - Testing'
	} else if ($store.state.environment === 'staging') {
		appName += ' - Staging'
	}

	document.title = (to.meta.title ? to.meta.title + ' | ' : '') + appName;
});

export default router;
