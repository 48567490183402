import OAuthUser from '@/models/OAuthUser';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	api: {
		async refreshToken() {
			try {
				const response = await fetchWrap('/api/integration/reset-token', { method: 'POST' });
				if (response.status === 200) {
					return await response.text();
				} else if (response.ok) {
					return '';
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
	},
	google: {
		async getAccount() {
			try {
				const response = await fetchWrap('/api/googleintegration/oauth-account');
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async refreshAccount() {
			try {
				const response = await fetchWrap('/api/googleintegration/oauth-account-refresh', { method: 'POST' });
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async getPickerInfo() {
			try {
				return await fetchWrap('/api/googleintegration/picker-info').then((response) => response.json());
			} catch {
				return null;
			}
		},
		async disableIntegration() {
			try {
				const response = await fetchWrap('/api/googleintegration/oauth-disable', { method: 'POST' });
				return response.ok;
			} catch {
				return false;
			}
		},
	},
	microsoft: {
		async getAccount() {
			try {
				const response = await fetchWrap('/api/microsoftintegration/oauth-account');
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async refreshAccount() {
			try {
				const response = await fetchWrap('/api/microsoftintegration/oauth-account-refresh', { method: 'POST' });
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async getPickerInfo() {
			try {
				return await fetchWrap('/api/microsoftintegration/picker-info').then((response) => response.json());
			} catch {
				return null;
			}
		},
		async getDrives() {
			let response;
			try {
				response = await fetchWrap('/api/microsoftintegration/drives');
			} catch {
				response = offlineResponse();
			}
			if (response.ok) {
				return await response.json();
			} else {
				return [];
			}
		},
		async disableIntegration() {
			try {
				const response = await fetchWrap('/api/microsoftintegration/oauth-disable', { method: 'POST' });
				return response.ok;
			} catch {
				return false;
			}
		}
	},
	fileStorageIntegration: {
		async disable() {
			try {
				const response = await fetchWrap('/api/filestorageintegration/oauth-disable', { method: 'POST' });
				return response.ok;
			} catch {
				return false;
			}
		},
		fallbackFile: Object.freeze({
			id: '',
			name: '',
			parents: [],
			url: '',
			canDownload: false,
			lastModified: '0001-01-01T00:00:00.0000000Z',
		}),
		async getFileById(id) {
			try {
				const response = await fetchWrap('/api/filestorageintegration/files/' + id);
				if (response.ok) {
					return await response.json();
				} else {
					return null;
				}
			} catch {
				return null;
			}
		}
	},
	hover: {
		async getAccount() {
			try {
				const response = await fetchWrap('/api/hoverintegration/oauth-account');
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async refreshAccount() {
			try {
				const response = await fetchWrap('/api/hoverintegration/oauth-account-refresh', { method: 'POST' });
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async clearCachedData() {
			try {
				const response = await fetchWrap('/api/hoverintegration/clear-cached-data', { method: 'POST' });
				if (response.ok) {
					return true;
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async disableIntegration() {
			try {
				const response = await fetchWrap('/api/hoverintegration/oauth-disable', { method: 'POST' });
				return response.ok;
			} catch {
				return false;
			}
		},
		async jobSearch(searchQuery, pageNumber) {
			const model = {
				address: searchQuery,
				page: pageNumber
			};
			try {
				const response = await fetchWrap('/api/hoverintegration/job-search', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(model),
				});
				if (response.ok) {
					return await response.json();
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async jobDetails(jobId) {
			const model = { jobId };
			try {
				const response = await fetchWrap('/api/hoverintegration/job-details', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(model),
				});
				if (response.ok) {
					return await response.json();
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async jobMeasurements(jobId) {
			const model = { jobId };
			try {
				const response = await fetchWrap('/api/hoverintegration/job-measurements', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(model),
				});
				if (response.ok) {
					return await response.json();
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
	},
	zoho: {
		async getAccount() {
			try {
				const response = await fetchWrap('/api/zohointegration/oauth-account');
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async getOrgDomainName() {
			try {
				const response = await fetchWrap('/api/zohointegration/org-domain-name', { method: 'POST' });
				if (response.ok) {
					return await response.text();
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async refreshAccount() {
			try {
				const response = await fetchWrap('/api/zohointegration/oauth-account-refresh', { method: 'POST' });
				if (response.ok) {
					const data = await response.json();
					return new OAuthUser(data);
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async refreshWebhookToken() {
			try {
				const response = await fetchWrap('/api/zohointegration/reset-webhook-token', { method: 'POST' });
				if (response.status === 200) {
					return await response.text();
				} else if (response.ok) {
					return '';
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async refreshUsers() {
			try {
				return await fetchWrap('/api/zohointegration/refresh-users', { method: 'POST' });
			} catch {
				return offlineResponse();
			}
		},
		async refreshLeads() {
			try {
				return await fetchWrap('/api/zohointegration/refresh-leads', { method: 'POST' });
			} catch {
				return offlineResponse();
			}
		},
		async clearCachedData() {
			try {
				const response = await fetchWrap('/api/zohointegration/clear-cached-data', { method: 'POST' });
				if (response.ok) {
					return true;
				} else {
					return response;
				}
			} catch {
				return offlineResponse();
			}
		},
		async disableIntegration() {
			try {
				const response = await fetchWrap('/api/zohointegration/oauth-disable', { method: 'POST' });
				return response.ok;
			} catch {
				return false;
			}
		}
	}
};
