import { getValueFromDto as getValue, getObjectFromDto as getObject } from './_helper';
import FileReference from './FileReference';

const optionIdPattern = /^\d+\.\d+\.\d+$/;

export default class EstimateFieldOption {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'string', null);
		this.value = getValue(dto, 'value', 'string', null);
		this.materialCost = getValue(dto, 'materialCost', 'number', 0);
		this.laborCost = getValue(dto, 'laborCost', 'number', 0);
		this.materialPrice = getValue(dto, 'materialPrice', 'number', 0);
		this.laborPrice = getValue(dto, 'laborPrice', 'number', 0);
		this.recommendedConversion1 = getValue(dto, 'recommendedConversion1', 'number', 0);
		this.recommendedConversion2 = getValue(dto, 'recommendedConversion2', 'number', 0);
		this.recommendedConversion3 = getValue(dto, 'recommendedConversion3', 'number', 0);
		this.description = getValue(dto, 'description', 'string', '');
		this.image = getObject(dto, 'image', FileReference);
	}

	get fieldId() {
		return EstimateFieldOption.getFieldId(this.id);
	}

	static getFieldId(optionId) {
		return optionIdPattern.test(optionId) ? optionId.substring(0, optionId.lastIndexOf('.')) : '';
	}

	static getWorkflowId(optionId) {
		return optionIdPattern.test(optionId) ? parseInt(optionId.substring(0, optionId.indexOf('.'))) : 0;
	}

	static valuesToHide = new Set(['n/a', 'no', 'none']);
}
