import companyId from '@/api/modules/companyId';
import { getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helper';

export default class DocumentSignature {
	constructor(dto) {
		this.url = getValue(dto, 'url', 'string', '');
		this.data = null;
		this.timestamp = getDate(dto, 'timestamp', null);
	}

	static getUrl(documentId, key) {
		return `/api/Documents/${documentId}/DownloadSignature?companyId=${companyId.get()}&key=${encodeURIComponent(key)}`;
	}
}
