import { clearDatabase, getDb } from "@/idbInit";

let _companyId = '';
const broadcast = new BroadcastChannel('sync');

broadcast.addEventListener('message', event => {
	if (typeof event.data === 'object' && event.data !== null) {
		if (event.data.key === 'companyId') {
			companyId.setFromIdb();
		}
	}
});

const companyId = {
	/**
	 * Get the companyId
	 * @returns the current company ID
	 */
	get() {
		return _companyId;
	},
	/**
	 * Set the companyId
	 * @param {Number|null} value the new company ID
	 */
	set(value) {
		if (typeof value === 'number' && value > 0) {
			_companyId = value;
		} else {
			_companyId = null;
		}
	},
	async setFromIdb() {
		const idb = await getDb();
		const id = await idb.get('info', 'companyId') ?? null;
		this.set(id);
	},
	async writeToIdbAndBroadcast() {
		await clearDatabase(true);
		const idb = await getDb();
		if (_companyId > 0) {
			await idb.put('info', _companyId, 'companyId');
		} else {
			await idb.delete('info', 'companyId');
		}
		broadcast.postMessage({ key: 'companyId' });
	},
	broadcastChannel: broadcast,
};

export default companyId;
