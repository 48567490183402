<template>
	<div class="account-nav-contain" :class="{ expanded: isOpen }" v-bind="$attrs" ref="accountNavWidget" v-on="focusListeners" tabindex="0">
		<button type="button" class="account-nav-toggle heading" @click="isOpen = !isOpen">
			<svg class="mr-quarter icon fill-white stroke-white">
				<use xlink:href="#account"></use>
			</svg>
			<span class="mt-quarter">
				{{ user.initials }}
			</span>
		</button>
		<nav class="account-nav">
			<router-link to="/account/companies?select=true">
				<svg class="icon">
					<use xlink:href="#compare"></use>
				</svg>
				Select company
			</router-link>
			<hr>
			<router-link to="/account/manage">
				<svg class="icon">
					<use xlink:href="#account-manage"></use>
				</svg>
				Manage account
			</router-link>
			<button type="button" @click="onLogout">
				<svg class="icon">
					<use xlink:href="#leave"></use>
				</svg>
				Logout
			</button>
			<div class="app-version">
				App Version: <span class="semi-bold">{{ version }}</span>
			</div>
		</nav>
	</div>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getFocus } from '@/composables/focus';
import MessageType from '@/models/MessageType';
import { safeComputed } from '@/composables/safeComputed';
import api from '@/api';

export default {
	inheritAttrs: false,
	setup() {
		const $store = useStore();
		const $router = useRouter();
		const accountNavWidget = ref(null);
		const { isOpen, focusListeners } = getFocus(accountNavWidget);
		const user = safeComputed(() => $store.state.auth.user);
		const confirmDialog = inject('confirmDialog');

		async function onLogout() {
			if ($store.state.unsyncedChanges > 0) {
				const confirmed = await confirmDialog.value.openDialog(
					`You have ${$store.state.unsyncedChanges} unsynced change(s). Are you sure you want to logout? You will lose any unsynced changes.`,
					{
						type: 'warning',
						confirmText: 'Logout',
					}
				);
				if (!confirmed) return;
			}
			if (confirmDialog.value && confirmDialog.value.isOpen) {
				confirmDialog.value.closeDialog();
			}
			const response = await api.users.logout();
			if (response.ok) {
				await $store.dispatch('refresh');
				await $store.dispatch('addMessage', {
					message: 'You have been logged out.',
					autoClose: true,
					type: MessageType.success,
				});
				$router.push('/');
			} else {
				return api.helpers.handleHttpError(response);
			}
		}

		return {
			isOpen,
			user,
			focusListeners,
			accountNavWidget,
			onLogout,
			version: process.env.VUE_APP_VERSION,
		};
	},
};
</script>
