<template>
	<input-number v-if="isInput" :id="htmlFieldName" :min="min" :max="max" :step="step === 0.333 ? 1 : step" v-bind="$attrs" v-model="modelValue" :defaultValue="min" :disabled="disabled" />
	<div class="select-wrap" v-else>
		<select :id="htmlFieldName" v-bind="$attrs" v-model.number="modelValue" :disabled="disabled">
			<option v-for="num in optionsList" :key="num" :value="num">
				{{ num.toLocaleString() }}
			</option>
		</select>
	</div>
	<div class="recommended-calc" :class="recommendedClass" @click="setRecommended" v-if="recommended >= 0">
		{{ recommended }} recommended
		<u>use</u>
	</div>
</template>

<script setup>
import EstimateField from '@/models/EstimateField';
import { useField } from 'vee-validate';
import { computed, inject, toRef } from 'vue';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	forceStyle: {
		type: String,
		required: false,
		default: '',
	},
	recommended: {
		type: Number,
		required: false,
		default: -1,
	},
	recommendedClass: {
		type: [Object, String],
		required: false,
		default: '',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const modelLookup = inject('modelLookup');
const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const { value: modelValue } = useField(toRef(props, 'name'));
const model = computed(() => modelLookup(props.name));
const field = computed(() => model.value ? model.value.field : null);

const min = computed(() => ((field.value ? field.value.min : null) ?? EstimateField.DEFAULT_MIN));
const max = computed(() => ((field.value ? field.value.max : null) ?? EstimateField.DEFAULT_MAX));
const step = computed(() => ((field.value ? field.value.step : null) ?? EstimateField.DEFAULT_STEP));
const optionsList = computed(() => {
	if (step.value === 0.333) {
		return [];
	}
	let options = [];
	for (let i = min.value; i <= max.value; i = i + step.value) {
		options.push(i);
	}
	return options;
});

const isInput = computed(() => props.forceStyle === 'input' || (props.forceStyle !== 'dropdown' && (optionsList.value.length === 0 || optionsList.value.length > 20)));

function setRecommended() {
	if (!props.disabled) {
		modelValue.value = props.recommended;
	}
}
</script>

<script>
export default { inheritAttrs: false }
</script>
