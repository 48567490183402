<template>
	<button type="button" :id="htmlFieldName" @click="value = !value" :class="{ 'checked': value, 'ui-check': !isSlider, 'ui-slider': isSlider }" :disabled="disabled"></button>
</template>

<script setup>
import { computed, inject, toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	isSlider: {
		type: Boolean,
		required: false,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});
const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const { value } = useField(toRef(props, 'name'));
</script>
