import { url as urlHelper } from '@/helpers';
import { getDb } from '@/idbInit';
import * as localChanges from '@/idbLocalChanges';
import * as localIds from '@/idbLocalIds';
import Estimate from '@/models/Estimate';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import { fetchWrap, notFoundResponse, offlineResponse } from '../_helpers';

async function assignLocalIds(data) {
	const itemMap = {};
	const childItemsMap = {};
	for (let i = 0; i < data.items.length; i++) {
		const x = data.items[i];
		itemMap[x.id] = x;
		if (x.parentItemId !== null) {
			if (!Array.isArray(childItemsMap[x.parentItemId])) {
				childItemsMap[x.parentItemId] = [];
			}
			childItemsMap[x.parentItemId].push(x);
		}
	}
	const idb = await getDb();
	for (let i = 0; i < data.items.length; i++) {
		const item = data.items[i];
		// tempItemIds are only even negative integers
		// idb localIds are only odd negative integers
		const oldId = item.id;
		if (oldId < 0 && -oldId % 2 === 0) {
			const newId = await localIds.getNewId(idb, 'estimate-items')
			item.id = newId;
			delete itemMap[oldId];
			itemMap[newId] = item;
			const children = childItemsMap[oldId];
			if (Array.isArray(children)) {
				for (let j = 0; j < children.length; j++) {
					const child = children[j];
					child.parentItemId = newId;
				}
				delete childItemsMap[oldId];
				childItemsMap[newId] = children;
			}
		}
	}
}

async function addDocumentsToRawEstimate(estimate, documents) {
	estimate.documents = JSON.parse(JSON.stringify(documents));
}

export default {
	async getAllByLeadId(leadId) {
		const idb = await getDb();
		leadId = await localIds.mapLocalId(idb, 'leads', leadId);
		const query = {};
		if (typeof leadId === 'number') {
			query.leadId = leadId;
		}
		const url = urlHelper('/api/estimates', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			const data = await idb.getAllFromIndex('estimates', 'leadId', leadId);
			return data.map(x => new Estimate(x));
		}
		if (response.ok) {
			const apiData = await response.json();
			const data = Array.from(apiData);
			const idbDataMap = (await idb.getAllFromIndex('estimates', 'leadId', leadId)).reduce((obj, x) => { obj[x.id] = x; return obj; }, {});
			const estimateChanges = (await idb.getAllFromIndex('local-changes', 'storeName', 'estimates')).reduce((obj, x) => { obj[x.id] = x; return obj; }, {});
			for (let i = 0; i < data.length; i++) {
				const apiItem = data[i];
				const changes = estimateChanges[apiItem.id];
				if (changes && changes.state === LocalChangeState.modified) {
					// if this item has been changed locally, use the local copy
					data[i] = idbDataMap[apiItem.id];
				} else if (changes && changes.state === LocalChangeState.deleted) {
					// remove if deleted locally
					data.splice(i, 1);
					i--;
				} else {
					await idb.put('estimates', apiItem, apiItem.id);
				}
			}
			// add any 'added' items to data
			Object.values(estimateChanges)
				.filter(x => x.state === LocalChangeState.added && idbDataMap[x.id])
				.forEach(x => data.push(x));

			return data.map(x => new Estimate(x));
		} else {
			return response;
		}
	},
	/**
	 * Get an estimate
	 * @param {id} Estimate Estimate ID
	 * @returns (async) Returns an Estimate if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		// if idb has changes for this id, use the idb value
		const idb = await getDb();
		const change = await idb.get('local-changes', LocalChange.getKey('estimates', id));
		if (change && change instanceof LocalChange) {
			if (change.state === LocalChangeState.added || change.state === LocalChangeState.modified) {
				const data = await idb.get('estimates', id);
				if (data) return new Estimate(data);
			} else if (change.state === LocalChangeState.deleted) {
				return notFoundResponse();
			}
		}
		let response;
		try {
			response = await fetchWrap('/api/estimates/' + id);
		} catch {
			const data = await idb.get('estimates', id);
			return data ? new Estimate(data) : offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			await idb.put('estimates', data, data.id);
			return new Estimate(data);
		} else {
			return response;
		}
	},
	/**
	 * Create an estimate
	 * @param {model} Estimate estimate to create.
	 * @returns (async) Returns the new Estimate if the request was successful, otherwise a Response.
	 */
	async create(model) {
		const idb = await getDb();
		model.leadId = await localIds.mapLocalId(idb, 'leads', model.leadId);
		model.documents.forEach(async x => {
			x.id = await localIds.mapLocalId(idb, 'estimate-documents', x.id);
			x.leadId = model.leadId
		});

		// don't include documents in API request
		const documents = model.documents;
		model = Object.assign({}, model);
		delete model.documents;

		const requestBody = JSON.stringify(model);
		let response;
		try {
			response = await fetchWrap('/api/estimates/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: requestBody,
			});
		} catch {
			const data = JSON.parse(requestBody);
			await assignLocalIds(data);
			await addDocumentsToRawEstimate(data, documents);
			if (data.id === 0) {
				data.id = await localIds.getNewId(idb, 'estimates');
			}
			await idb.put('estimates', data, data.id);
			await localChanges.add(idb, new LocalChange({ storeName: 'estimates', id: data.id, state: LocalChangeState.added }));
			return new Estimate(data);
		}
		if (response.ok) {
			const data = await response.json();
			await localIds.addLocalIdMap(idb, 'estimates', model.id, data.id);
			await idb.delete('estimates', model.id);
			await idb.put('estimates', data, data.id);
			await localChanges.deleteChange(idb, LocalChange.getKey('estimates', model.id));
			return new Estimate(data);
		} else {
			return response;
		}
	},
	/**
	 * Update an estimate
	 * @param {model} Estimate estimate to update.
	 * @returns (async) Returns the updated Estimate if the request was successful, otherwise a Response.
	 */
	async update(model) {
		const idb = await getDb();
		model.id = await localIds.mapLocalId(idb, 'estimates', model.id);
		model.leadId = await localIds.mapLocalId(idb, 'leads', model.leadId);
		model.documents.forEach(async x => {
			x.id = await localIds.mapLocalId(idb, 'estimate-documents', x.id);
			x.leadId = model.leadId
		});

		// don't include documents in API request
		const documents = model.documents;
		model = Object.assign({}, model);
		delete model.documents;

		const requestBody = JSON.stringify(model);
		let response;
		try {
			response = await fetchWrap('/api/estimates/' + model.id, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: requestBody,
			});
		} catch {
			const data = JSON.parse(requestBody);
			await assignLocalIds(data);
			await addDocumentsToRawEstimate(data, documents);
			await idb.put('estimates', data, data.id);
			await localChanges.add(idb, new LocalChange({ storeName: 'estimates', id: data.id, state: LocalChangeState.modified }));
			return new Estimate(data);
		}
		if (response.ok) {
			const data = await response.json();
			await idb.put('estimates', data, data.id);
			await localChanges.deleteChange(idb, LocalChange.getKey('estimates', model.id));
			return new Estimate(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete an estimate
	 * @param {id} Number Estimate ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the estimate could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		const idb = await getDb();
		id = await localIds.mapLocalId(idb, 'estimates', id);
		let response;
		try {
			response = await fetchWrap('/api/estimates/' + id, { method: 'DELETE' });
		} catch {
			let cannotDelete = (await idb.countFromIndex('estimate-documents', 'estimateId', id)) > 0;
			if (cannotDelete) {
				return false;
			} else {
				await idb.delete('estimates', id);
				await localChanges.add(idb, new LocalChange({ storeName: 'estimates', id: id, state: LocalChangeState.deleted }));
				return true;
			}
		}
		if (response.ok || response.status === 404) {
			await idb.delete('estimates', id);
			await localChanges.deleteChange(idb, LocalChange.getKey('estimates', id));
			return true;
		} else if (response.status === 409) {
			await localChanges.deleteChange(idb, LocalChange.getKey('estimates', id));
			return false;
		} else {
			return response;
		}
	}
};
