<template>
	<div class="is-loading bars">
		<div class="bars-contain">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>
