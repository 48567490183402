import LocalChangeState from './models/LocalChangeState';
import LocalChange from './models/LocalChange';
import $store from './store/index';

export async function add(db, change) {
	let action = null;
	if (change.state === LocalChangeState.added) {
		// always add
		action = 'put';
	} else if (change.state === LocalChangeState.modified) {
		const currentChange = await db.get('local-changes', change.key);
		if (currentChange && currentChange.state === LocalChangeState.added) {
			// avoid setting added to modified
			change.state = LocalChangeState.added;
			action = 'put';
		} else {
			action = 'put';
		}
	} else if (change.state === LocalChangeState.deleted) {
		const currentChange = await db.get('local-changes', change.key);
		if (currentChange && currentChange.state === LocalChangeState.added) {
			action = 'delete';
		} else {
			action = 'put';
		}
	}
	if (action === 'put') {
		await db.put('local-changes', change, change.key);
		await updateVuex(db);
	} else if (action == 'delete') {
		await db.delete('local-changes', change.key);
		await updateVuex(db);
	}
}

export async function deleteChange(db, changeKey) {
	await db.delete('local-changes', changeKey);
	await updateVuex(db);
}

export async function getAll(db, storeName, state) {
	return (await db.getAllFromIndex('local-changes', 'storeName', storeName)).filter(x => x.state === state).map(x => new LocalChange(x));
}

async function updateVuex(db) {
	const unsyncedChanges = await db.count('local-changes');
	$store.commit('setUnsyncedChanges', unsyncedChanges);
}
