import { getValueFromDto as getValue, getDateTimeFromDto as getDate, getArrayFromDto } from './_helper';

export class EmailStatus {
	static failure = 0;
	static success = 1;
	static pending = 2;
	static sending = 3;
	static skipped = 4;

	static options = [
		{ id: this.failure, name: 'failure', },
		{ id: this.success, name: 'success', },
		{ id: this.pending, name: 'pending', },
		{ id: this.sending, name: 'sending', },
		{ id: this.skipped, name: 'skipped', },
	]

	static getName(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}
}

export class DocumentEmailHistory {
	constructor(dto) {
		this.documentId = getValue(dto, 'documentId', 'number', null);
		this.emailAddresses = getArrayFromDto(dto, 'emailAddresses');
		this.timestamp = getDate(dto, 'timestamp', null);
		this.status = getValue(dto, 'status', 'number', EmailStatus.skipped);
	}
}
