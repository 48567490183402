<template>
	<input type="number" :value="value" @input="updateModelValue($event.target.value)" @focus="onFocusChange" @blur="onFocusChange" ref="input" :placeholder="defaultValue" :disabled="disabled" />
</template>

<script>
import { nextTick, ref, watch } from 'vue';

export default {
	props: {
		modelValue: {
			type: Number,
			required: true,
		},
		defaultValue: {
			type: Number,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	setup(props, context) {
		const input = ref(null);
		const value = ref('');
		onFocusChange();

		async function onFocusChange() {
			if (props.modelValue === props.defaultValue) {
				value.value = '';
			} else if (typeof props.modelValue === 'number' && !isNaN(props.modelValue)) {
				value.value = props.modelValue;
			} else {
				value.value = props.defaultValue;
			}
			await nextTick();
			if (input.value) {
				input.value.value = value.value;
			}
		}

		watch(
			() => props.modelValue,
			() => {
				if (!(input.value && input.value.value === '' && value.value === '' && props.modelValue === props.defaultValue)) {
					value.value = props.modelValue;
				}
			}
		);

		function updateModelValue(v) {
			let numberValue = parseFloat(v);
			if (isNaN(numberValue)) {
				numberValue = props.defaultValue;
			}
			if (v === '') {
				value.value = '';
			}
			context.emit('update:modelValue', numberValue);
		}

		return {
			input,
			value,
			updateModelValue,
			onFocusChange,
		};
	},
};
</script>
