import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	async get(keys) {
		let response;
		try {
			response = await fetchWrap('/api/info?keys=' + keys.join(','));
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			return await response.json();
		} else {
			return response;
		}
	},
};
