import { url as urlHelper } from '@/helpers';
import ZohoUser from '@/models/ZohoUser';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Get all zoho users, filtered by the following parameters.
	 * @param {Object} params
	 * @param {boolean} params.active
	 * @returns (async) Returns a Response if the request was made without network errors, or undefined if a network error occurred.
	 */
	async getAll({ active = undefined } = {}) {
		const query = {};
		if (typeof active === 'boolean') {
			query.active = active;
		}
		const url = urlHelper('/api/zohousers', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return data.map(x => new ZohoUser(x));
		} else {
			return response;
		}
	},
};
